import { useAppSelector } from "@config/redux/hook";
import {
	type JobboardSliceState,
	setShowCreateSearchModal,
	setShowSearchesModal,
} from "@containers/student/BetaJobboard/core/store/betaJobboard.slice";
import { SearchHistory } from "@containers/student/BetaJobboard/ui/jobboard/ui/SearchHistory/SearchHistory";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { Modal } from "@designSystem/organisms/modal/Modal";
import React from "react";
import { useDispatch } from "react-redux";

export const SearchHistoryModal = () => {
	const dispatchJobboard = useDispatch();
	const { showSearchesModal, loadSearchHistory } =
		useAppSelector<JobboardSliceState>((state) => {
			return state.betaJobboard;
		});
	return (
		<Modal
			title={"Mes recherches"}
			show={showSearchesModal}
			onClose={() => "Todo"}
			body={<SearchHistory />}
			buttonsRight={[
				<ButtonPrimary
					label="Nouvelle recherche"
					disabled={loadSearchHistory}
					onClick={() => {
						dispatchJobboard(setShowSearchesModal(false));
						dispatchJobboard(setShowCreateSearchModal(true));
					}}
				/>,
			]}
		/>
	);
};
