import type { SearchHistoryType } from "@containers/student/BetaJobboard/core/types/jobboard.types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { StandardGraphQLResponse } from "@tools/API";
import {
	createOfferSearch,
	deleteOfferSearch,
	getSchoolOffersCount,
	getStudentOffersSearches,
	retrieveSchoolOffers,
	retrieveScrapedOffers,
} from "../api/jobboard.api";

export const fetchSchoolOffers = createAsyncThunk<
	StandardGraphQLResponse,
	string
>("betaJobboardAPI/fetchSchoolOffers", async (params: string) => {
	const response = await retrieveSchoolOffers(params);
	if (response.responseLabel === "error" && response.message === "aborted") {
		throw new Error("aborted");
	}
	if (response.responseLabel === "error") {
		throw new Error(response.message);
	}
	return response;
});

type FetchScrapedOffersParamsType = {
	search: string;
	algoliaFilters: object;
};
export const fetchScrapedOffers = createAsyncThunk<
	StandardGraphQLResponse,
	FetchScrapedOffersParamsType
>(
	"betaJobboardAPI/fetchScrapedOffers",
	async (params: FetchScrapedOffersParamsType) => {
		const response = await retrieveScrapedOffers(
			params.search,
			params.algoliaFilters,
		);
		if (response.responseLabel === "error" && response.message === "aborted") {
			throw new Error("aborted");
		}
		if (response.responseLabel === "error") {
			throw new Error(response.message);
		}
		return response;
	},
);

export const fetchSchoolOffersCount = createAsyncThunk<
	StandardGraphQLResponse,
	string
>("betaJobboardAPI/fetchSchoolOffersCount", async (params: string) => {
	const response = await getSchoolOffersCount(params);
	if (response.responseLabel === "error" && response.message === "aborted") {
		throw new Error("aborted");
	}
	if (response.responseLabel === "error") {
		throw new Error(response.message);
	}
	return response;
});

export const getStudentHistory = createAsyncThunk<
	StandardGraphQLResponse,
	string
>("betaJobboardAPI/getStudentHistory", async (studentId: string) => {
	const response = await getStudentOffersSearches(studentId);
	if (response.responseLabel === "error" && response.message === "aborted") {
		throw new Error("aborted");
	}
	if (response.responseLabel === "error") {
		throw new Error(response.message);
	}
	return response;
});

export const deleteSearchHistoryItem = createAsyncThunk<
	StandardGraphQLResponse,
	string
>("betaJobboardAPI/deleteSearchHistoryItem", async (offerSearchId: string) => {
	const response = await deleteOfferSearch(offerSearchId);
	if (response.responseLabel === "error" && response.message === "aborted") {
		throw new Error("aborted");
	}
	if (response.responseLabel === "error") {
		throw new Error(response.message);
	}
	return response;
});

export const createHistorySearch = createAsyncThunk<
	StandardGraphQLResponse,
	Partial<SearchHistoryType>
>(
	"betaJobboardAPI/createHistorySearch",
	async (offerSearch: Partial<SearchHistoryType>) => {
		const response = await createOfferSearch(offerSearch);
		if (response.responseLabel === "error" && response.message === "aborted") {
			throw new Error("aborted");
		}
		if (response.responseLabel === "error") {
			throw new Error(response.message);
		}
		return response;
	},
);
