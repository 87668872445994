import type { EntOffersType } from "@/types/EntCompanyTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import UsersIllustration from "@assets/illustrations/entreprise/folder-illustration.svg";
import FolderIllustration from "@assets/illustrations/entreprise/folder-illustration.svg";
import CVsIllustration from "@assets/illustrations/entreprise/folder-illustration.svg";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { WhiteCard } from "@designSystem/molecules/WhiteCard";

import type { AppState } from "@config/redux/store";
import type { CompanySliceType } from "@containers/company/slice/companySlice";
import { errorToast } from "@tools/Toasts";
import { login } from "../auth/Login/LoginAPI";
import { LoginForm } from "../auth/LoginCompany/ui/LoginForm";
import { queryLatestOffers } from "./HomepageAPI";
import { HomepageOffers } from "./ui/HomepageOffers";
export function CompanyHomepage() {
	const [loading, setLoading] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const [latestSelections, setLatestSelections] = useState<EntOffersType[]>([]);
	const [selectionsLoading, setSelectionsLoading] = useState(true);
	const [showFutureContent] = useState(false);

	const { user } = useContext(GlobalContext);
	const navigate = useNavigate();

	const { school, companySchoolProfile, entOffers } = useAppSelector(
		(state: AppState) => state.company as CompanySliceType,
	);
	const getLatestSelections = async () => {
		if (companySchoolProfile) {
			const filteredOffers = entOffers.filter((item: EntOffersType) => {
				return (
					item.status && ["active", "pending", "new"].includes(item.status)
				);
			});
			if (entOffers.length === 0) {
				try {
					const data = await queryLatestOffers(companySchoolProfile.id);
					setLatestSelections(data);
					setSelectionsLoading(false);
				} catch {
					errorToast(
						"Une erreur est survenue lors de la récupération des dernières offres",
					);
				}
			} else {
				const latest = filteredOffers.slice(0, 2);
				setLatestSelections(latest);
				setSelectionsLoading(false);
			}
		} else {
			setSelectionsLoading(false);
		}
	};
	useEffect(() => {
		if (user) {
			getLatestSelections();
		}
	}, [user, companySchoolProfile]);

	return (
		<div className="mx-auto mt-sm">
			{user && (
				<HomepageOffers
					selectionsLoading={selectionsLoading}
					latestSelections={latestSelections}
				/>
			)}

			<div className="mt-md flex gap-sm">
				<WhiteCard
					className="!h-auto w-[325px] justify-between"
					title="Vous avez une offre à déposer ?"
					content={
						<>
							<img
								alt="Un homme qui ouvre un dossier"
								className="mx-auto mb-sm"
								src={FolderIllustration as string}
							/>
							<p className="mb-md">
								Partagez une offre aux étudiants de {school?.name}.
							</p>
							<ButtonSecondary
								className="mx-auto"
								onClick={() => {
									navigate(
										user
											? `/company-offers/create?${school?.subdomain || ""}`
											: `/company/login?${school?.subdomain || ""}`,
									);
								}}
								label={
									user && !companySchoolProfile
										? "Associer mon entreprise"
										: "Déposer une offre d’emploi"
								}
							/>
						</>
					}
				/>
				{showFutureContent && (
					<>
						<WhiteCard
							className="!h-auto w-[325px] justify-between"
							title="Vous souhaitez accéder à des profils?"
							content={
								<>
									<img
										alt="Un homme qui ouvre un dossier"
										className="mx-auto mb-sm"
										src={CVsIllustration as string}
									/>
									<p className="mb-md">Découvrez nos profils disponibles.</p>
									<ButtonSecondary
										className="mx-auto"
										onClick={() => {
											navigate(
												user
													? `/company-selections?${school?.subdomain || ""}`
													: `/company/login?${school?.subdomain || ""}`,
											);
										}}
										label="Accéder à la CVthèque"
									/>
								</>
							}
						/>
						<WhiteCard
							className="!h-auto w-[325px] justify-between"
							title="Vous voulez voir qui a accès ?"
							content={
								<>
									<img
										alt="Un homme qui ouvre un dossier"
										className="mx-auto mb-sm"
										src={UsersIllustration as string}
									/>
									<p className="mb-md">Ayez la main sur les utilisateurs.</p>
									<ButtonSecondary
										className="mx-auto"
										onClick={() => {
											navigate(
												user
													? `/users?${school?.subdomain || ""}`
													: `/company/login?${school?.subdomain || ""}`,
											);
										}}
										label="Gestion utilisateurs"
									/>
								</>
							}
						/>
					</>
				)}
			</div>
			{!user && (
				<LoginForm
					className="mt-md"
					loading={loading}
					setLoading={setLoading}
					hasErrors={hasErrors}
					setHasErrors={setHasErrors}
					onSubmit={login}
				/>
			)}
		</div>
	);
}
