import { COMPANY_RELATIONS_OPTIONS } from "@constants/Company";
import React from "react";

import { SelectV2 } from "@/designSystem/organisms/selectV2/SelectV2";
import { useAppDispatch } from "@config/redux/store";
import { companiesManagementSlice } from "../../core/store/companiesManagement.slice";

type SelectRelationToCompanyProps = {
	triggerUpdate?: (valueFromInput: unknown) => void;
	choosenRelation?: number;
};
export function SelectRelationToCompany({
	triggerUpdate,
	choosenRelation,
}: SelectRelationToCompanyProps) {
	const modifyCompanyToCreate =
		companiesManagementSlice.actions.modifyCompanyToCreate;
	const dispatchEvent = useAppDispatch();

	const handleRelationChange = (option: string) => {
		if (triggerUpdate) triggerUpdate(option);

		dispatchEvent(modifyCompanyToCreate({ relation: option }));
	};

	const findDefaultOption = () => {
		const optionAlreadySelected = COMPANY_RELATIONS_OPTIONS.find(
			(option) => option.value === choosenRelation,
		);
		return optionAlreadySelected
			? optionAlreadySelected.value
			: COMPANY_RELATIONS_OPTIONS[1].value.toString();
	};
	return (
		<article className="w-full">
			<SelectV2
				name="Relation"
				onSelectOption={handleRelationChange}
				label={"Quelle relation avez-vous avec cette entreprise ? "}
				isRequired
				options={COMPANY_RELATIONS_OPTIONS}
				useFullWidth
				preselectedOptionValue={findDefaultOption()}
			/>
		</article>
	);
}
