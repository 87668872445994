import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { Spinner } from "@designSystem/atoms/Spinner";
import { ApplicationItem } from "@designSystem/organisms/ApplicationItem";
import { Modal } from "@designSystem/organisms/modal/Modal";

import { getApplication } from "../../../StudentDetailAPI";

import { Informations } from "./ApplicationDetailModal/Informations";

export function DisplayApplicationModal({
	show,
	onClose,
	student,
	application,
	goPrevious,
	goNext,
}) {
	const modalRef = useRef();
	const [load, setLoad] = useState(true);
	const [displayApplication, setDisplayApplication] = useState({});

	const handleBackClick = () => {
		modalRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const getApplicationDetail = async () => {
		setLoad(true);
		const sideApplicationData = await getApplication(application.id);

		if (sideApplicationData) {
			setDisplayApplication({ ...application, ...sideApplicationData });
		}
		setLoad(false);
		return sideApplicationData;
	};

	useEffect(() => {
		if (show) {
			getApplicationDetail();
		}
	}, [show]);

	useEffect(() => {
		setDisplayApplication(application);
	}, [application]);

	return (
		<div>
			<Modal
				className="display-application"
				title={`Candidature de ${student.firstname} ${student.lastname}`}
				scroll
				show={show}
				onClose={() => onClose()}
				body={
					<div ref={modalRef} className="pl-md pr-md">
						{load ? (
							<div className="flex justify-center">
								<Spinner />
							</div>
						) : (
							<>
								<ApplicationItem
									className="mt-xsm"
									application={displayApplication}
									companyName={displayApplication?.offer?.crmCompany?.name}
								/>
								<Informations
									notes={displayApplication.notes}
									actionsProps={displayApplication.actions?.collection}
									applicationProps={displayApplication}
									contactProps={displayApplication.contacts?.collection}
									step={displayApplication.step}
								/>
							</>
						)}
					</div>
				}
				buttonsRight={[
					<ButtonSecondary
						onClick={() => {
							goPrevious();
							handleBackClick();
						}}
						label="Précédent"
					/>,
					<ButtonPrimary
						onClick={() => {
							goNext();
							handleBackClick();
						}}
						label="Suivant"
					/>,
				]}
			/>
		</div>
	);
}

DisplayApplicationModal.propTypes = {
	show: PropTypes.bool.isRequired,
	goPrevious: PropTypes.func.isRequired,
	goNext: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	application: PropTypes.shape({
		organization: PropTypes.shape({}),
		notes: PropTypes.shape({}),
		actions: PropTypes.shape({
			collection: PropTypes.arrayOf(PropTypes.any).isRequired,
		}),
		contacts: PropTypes.shape({
			collection: PropTypes.arrayOf(PropTypes.any).isRequired,
		}),
	}).isRequired,
	student: PropTypes.shape({
		notes: PropTypes.arrayOf(PropTypes.any).isRequired,
		firstname: PropTypes.string,
		lastname: PropTypes.string,
	}).isRequired,
};
