import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { Onboarding } from "@modules/onboarding/Onboarding";

import AnnouncementsModal from "@containers/shared/AnnoncementsModal/index";
import { StepsToCompletAccordion } from "@containers/student/StudentDashboard/StepsToCompletAccordion";
import { TabsBlock } from "@containers/student/StudentDashboard/TabsBlock";

import { EOnboarding } from "@constants/Others";
import { SoftrViewer } from "@modules/softr/SoftrViewer";
import Goals from "./Goals";

export function StudentDashboard() {
	const { user, setUser } = useContext(GlobalContext);
	const { setTitle }: { setTitle: (title: string) => void } =
		useOutletContext();

	const [tabIndex, setTabIndex] = useState(0);
	const [displayOnBoarding, setDisplayOnBoarding] = useState(false);
	const [displayAnnoncements, setDisplayAnnoncements] = useState<boolean>(true);

	useEffect(() => {
		setTitle(`Bienvenue sur ton espace ${user?.schoolName}`);
		if (user?.hasSeenNews !== undefined) {
			setDisplayAnnoncements(!user?.hasSeenNews);
		}
	}, []);

	useEffect(() => {
		if (!displayAnnoncements && user) {
			if (!user.onboardingSteps) {
				return;
			}
			const hasSeenOnboarding =
				user.onboardingSteps?.split("")[EOnboarding.DASHBOARD];
			if (!hasSeenOnboarding) return;
			setDisplayOnBoarding(Number.parseInt(hasSeenOnboarding) === 0);
		}
	}, [displayAnnoncements]);

	return (
		<main className="flex flex-col gap-md">
			<StepsToCompletAccordion setTabInformation={setTabIndex} />
			<div className="flex flex-col md:flex-row w-full gap-md">
				<div className="w-full md:w-5/12 xl:w-4/12 ">
					<Goals />
					<div className="mt-4 hidden md:block">
						<SoftrViewer
							templateID="bbbccfb6-c2e5-4192-89e4-78f87be249fe"
							subdomain="brinda4417"
							className="w-full rounded-lg overflow-hidden shadow-md flex justify-center items-center min-w-full !p-0 !m-0"
						/>
					</div>
				</div>
				<TabsBlock selectedTab={tabIndex} />
				<div className="mt-4 md:hidden block">
					<SoftrViewer
						templateID="bbbccfb6-c2e5-4192-89e4-78f87be249fe"
						subdomain="brinda4417"
						className="w-full rounded-lg overflow-hidden shadow-md flex justify-center items-center min-w-full !p-0 !m-0"
					/>
				</div>
			</div>
			<Onboarding
				currentPath={EOnboarding.DASHBOARD}
				show={displayOnBoarding}
				onClose={() => {
					setDisplayOnBoarding(false);
				}}
			/>
			<AnnouncementsModal
				show={displayAnnoncements}
				onClose={() => {
					setUser({
						...user,
						hasSeenNews: true,
					});
					setDisplayAnnoncements(false);
				}}
				link="https://sterling6914.softr.app/"
			/>
		</main>
	);
}
