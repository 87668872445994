import type { SchoolOfferType } from "@/types/offerTypes";
import { fetchGetCompanyOffers } from "../api/companiesManagement.request";
import type { ICompanyOffersGateway } from "../gateway/companyOffers.gateway";
export class InAPICompanyOffersGateway implements ICompanyOffersGateway {
	async getCompanyOffers(
		companyId: string,
		targetStatus: string,
	): Promise<SchoolOfferType[]> {
		const response = await fetchGetCompanyOffers(companyId, targetStatus);
		if (response.responseLabel === "error" && response.message !== "aborted") {
			throw new Error(
				"Erreur lors du chargement des informations de l'entreprise",
			);
		}
		if (response.data.offers.collection) {
			return response.data.offers.collection;
		}
		throw new Error("Erreur, format de données incorrect");
	}
}
