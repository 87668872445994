import type { promotionType } from "@/types/promotionTypes";
import type { FiltersType } from "@/types/usersFiltersTypes";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useOutletContext } from "react-router-dom";

import { resolveView } from "@tools/Users";

import { getPromotions } from "@modules/usersFilters/core/api/usersFilters.api";
import {
	countCurrentFilters,
	setShowFilters,
} from "@modules/usersFilters/core/store/usersFilters.slice";
import { success } from "@tools/Toasts";
import { getRequestedUsersCount } from "./StudentsManagementAPI";
import {
	type FetchStudensListParamsType,
	fetchStudentsList,
	setRequestedUsersCount,
	setRequestedUsersCountLoading,
} from "./studentsManagementSlice";
import { Head } from "./ui/Head";
import { StudentsList } from "./ui/StudentsList";

type StudentManagementContextType = {
	displayNewStudent: boolean;
	setDisplayNewStudent: Function;
	STUDENTS_DEFAULT_FILTERS: any;
	promotions: promotionType[];
};
export const StudentsManagementContext = createContext(
	{} as StudentManagementContextType,
);

export type ViewType = {
	school?: string;
	campus?: string;
	[key: string]: any;
};
export function StudentsManagement() {
	const STUDENTS_DEFAULT_FILTERS = {
		state: "all",
		page: 1,
		spsPromotion_archived: false,
	};

	const { user } = useContext(GlobalContext);
	const location = useLocation();
	const {
		structureView,
		setTitle,
	}: { structureView: ViewType; setTitle: Function } = useOutletContext();
	const dispatchEvent = useDispatch();
	const { currentFilters, currentSearch, showFilters } = useAppSelector(
		(state) => state.usersFilters,
	);

	const [displayNewStudent, setDisplayNewStudent] = useState<boolean>(false);
	const [currentPromotions, setCurrentPromotions] = useState<promotionType[]>(
		[],
	);

	const manageStateFilters = (filtersCopy: Partial<FiltersType>) => {
		if ("state" in filtersCopy && filtersCopy.state === "all") {
			filtersCopy.state = undefined;
		}
		return filtersCopy;
	};

	const constructFiltersCopy = (initialUseSearchValue: boolean) => {
		const filtersCopy = initialUseSearchValue
			? {
					search: currentSearch,
					state: currentFilters?.state,
					page: currentFilters?.page,
				}
			: currentSearch !== undefined &&
					currentSearch !== null &&
					currentSearch !== ""
				? {
						search: currentSearch,
						state: currentFilters?.state,
						page: currentFilters?.page,
					}
				: showFilters
					? { ...currentFilters }
					: ({
							order: currentFilters?.order,
							state: currentFilters?.state,
							page: currentFilters?.page,
						} as Partial<FiltersType>);
		return filtersCopy;
	};

	const buildParams = (
		useInitialStateOfFilters: boolean,
	): FetchStudensListParamsType => {
		let filtersCopy: Partial<FiltersType> = constructFiltersCopy(
			useInitialStateOfFilters,
		);
		filtersCopy = manageStateFilters(filtersCopy);

		return {
			formatView: { ...resolveView(structureView, "spsPromotion") },
			filters: filtersCopy,
		} as FetchStudensListParamsType;
	};

	const getStudents = async (useInitialStateOfFilters: boolean) => {
		await dispatchEvent(
			fetchStudentsList(buildParams(useInitialStateOfFilters)) as any,
		);
	};

	const getTotalRequestedUsers = async () => {
		const total = await getRequestedUsersCount({
			...resolveView(structureView, "spsPromotion"),
			search: currentSearch,
		});
		dispatchEvent(setRequestedUsersCount(total));
		dispatchEvent(setRequestedUsersCountLoading(false));
	};

	useEffect(() => {
		setTitle("Gestion des étudiants");
		getStudents(false);
		if (location.state?.isCreateStudent) {
			success("Étudiant crée avec succès.");
		}
		if (location.state?.isDeletingStudent) {
			success("Étudiant supprimé avec succès.");
		}
		location.state = {};
	}, [currentFilters, structureView, showFilters]);

	useEffect(() => {
		if (
			user !== null &&
			currentSearch !== undefined &&
			currentSearch !== null
		) {
			getStudents(true);
		}
	}, [currentSearch]);

	useEffect(() => {
		dispatchEvent(
			setShowFilters(!(currentSearch !== "" && currentSearch !== undefined)),
		);
		dispatchEvent(countCurrentFilters());
		getPromotions(resolveView(structureView)).then(
			({ collection }: { collection: promotionType[] }) => {
				setCurrentPromotions(collection);
			},
		);
	}, []);

	useEffect(() => {
		getTotalRequestedUsers();
	}, [structureView, currentSearch]);

	return (
		<StudentsManagementContext.Provider
			value={{
				displayNewStudent,
				setDisplayNewStudent,
				STUDENTS_DEFAULT_FILTERS,
				promotions: currentPromotions,
			}}
		>
			<main className="flex flex-col gap-md">
				<Head />
				<StudentsList buildParams={buildParams} />
			</main>
		</StudentsManagementContext.Provider>
	);
}
