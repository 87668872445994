import { briefcaseOutline, pinOutline, trash2Outline } from "@assets/Icons";
import { useAppSelector } from "@config/redux/hook";
import {
	type JobboardSliceState,
	setSelectedSearchHistoryItem,
} from "@containers/student/BetaJobboard/core/store/betaJobboard.slice";
import { deleteSearchHistoryItem } from "@containers/student/BetaJobboard/core/store/betaJobboard.thunks";
import type { SearchHistoryType } from "@containers/student/BetaJobboard/core/types/jobboard.types";
import { IconButton } from "@designSystem/atoms/IconButton";
import { Icon } from "@iconify/react";
import { getContractType } from "@tools/Offers";
import React from "react";
import { useDispatch } from "react-redux";

export const SearchHistoryItem = ({
	searchHistoryItem,
}: {
	searchHistoryItem: SearchHistoryType;
}) => {
	const dispatchJobboard = useDispatch();
	const { selectedSearchHistoryItem } = useAppSelector<JobboardSliceState>(
		(state) => state.betaJobboard,
	);
	const handleItemClick = () => {
		dispatchJobboard(setSelectedSearchHistoryItem(searchHistoryItem));
	};

	const handleDeleteSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		dispatchJobboard(deleteSearchHistoryItem(searchHistoryItem.id) as any);
	};

	return (
		<div
			className={`flex items-center justify-between shadow-sm px-4 py-2 rounded-md cursor-pointer border ${
				selectedSearchHistoryItem?.id === searchHistoryItem.id
					? "border-primary-500"
					: "border-transparent"
			}`}
			onClick={handleItemClick}
		>
			<div className="flex flex-col gap-xxsm text-primary-700P">
				<p className=" font-bold text-xsm">{searchHistoryItem.search}</p>
				<p className="flex text-xxsm items-center gap-xsm">
					<span className="flex gap-xxsm items-center">
						<Icon icon={briefcaseOutline} width={16} height={16} />
						{getContractType(searchHistoryItem.contract).label}
					</span>
					|
					<span className="flex gap-xxsm items-center">
						<Icon icon={pinOutline} width={16} height={16} />
						{searchHistoryItem.city}
					</span>
				</p>
			</div>
			<IconButton icon={trash2Outline} onClick={handleDeleteSearch} />
		</div>
	);
};
