import React from "react";

import { briefcaseOutline, pinOutline } from "@assets/Icons";
import { useAppSelector } from "@config/redux/hook";
import {
	type JobboardSliceState,
	setShowSearchesModal,
} from "@containers/student/BetaJobboard/core/store/betaJobboard.slice";
import { Icon } from "@iconify/react";
import { getContractType } from "@tools/Offers";
import { useDispatch } from "react-redux";

export function Head() {
	const dispatchJobboard = useDispatch();
	const { selectedSearchHistoryItem } = useAppSelector<JobboardSliceState>(
		(state) => {
			return state.betaJobboard;
		},
	);
	return (
		<section className="flex flex-col gap-sm p-sm bg-white">
			<p className="font-bold text-xsm">Ma recherche</p>
			{selectedSearchHistoryItem && (
				<div
					className="flex flex-1 justify-between text-primary-700P shadow-sm px-4 py-2 rounded-md cursor-pointer border border-primary-500"
					onClick={() => {
						dispatchJobboard(setShowSearchesModal(true));
					}}
				>
					<p className=" font-bold text-xsm">
						{selectedSearchHistoryItem.search}
					</p>
					<div className="flex gap-xxsm">
						<p className="flex text-xxsm items-center gap-xsm">
							<span className="flex gap-xxsm items-center">
								<Icon icon={briefcaseOutline} width={16} height={16} />
								{getContractType(selectedSearchHistoryItem.contract).label}
							</span>
							|
							<span className="flex gap-xxsm items-center">
								<Icon icon={pinOutline} width={16} height={16} />
								{selectedSearchHistoryItem.city}
							</span>
						</p>
					</div>
				</div>
			)}
		</section>
	);
}
