import type { SchoolOfferType } from "@/types/offerTypes";

import API, { checkGraphQLError, graphqlRequestConstructor } from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";

export async function getOfferDetails(id: string) {
	try {
		const queryToSend = {
			query: `
      query {
          offer  (id: "${id}"){
            id
            responsable {
              id
              firstname
              lastname
              avatar{
                filePath
              }
            }
            entOffer {
              id
            }
            selection {
				id
				sendDate
				type
				sharedData
				selectionUsers (itemsPerPage: 1000) {
				  collection {
					user: spsStudent {
					  id
					}
				  }
				}
            }
            crmCompany {
              id
              name
            }
            campuses{
              collection{
                id
                name
              }
            }
            tags
            pdf {
              id
              filePath
              size
            }
            description
            date
            anonymous
            job
            status
            views
            contract
            type
            visibility
            telework
            qualification
            motivationLetterRequired
            location
            latitude
            longitude
            applicationsCount
            applicationsToTreat
						sentToCompanyCount
            sharesCount
            updateAt
            expirationDate
            wayToApply
            closeAt
            closeReason
            openPositionsCount
            externalId
            externalLink
          }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la récupération des offres",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}
export async function getEntOfferDetails(id: string) {
	try {
		const queryToSend = {
			query: `
      query {
          offer: entOffer(id: "${id}"){
            id
            job
            description
            location
            latitude
            longitude
            contract
            telework
            pdf {
              id
              filePath
              size
            }
            qualification
            status
            url:emailPreference
          }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(response, "Offres récupérées.");
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function modifyVisibility(id: string, visibility: string) {
	try {
		const queryToSend = {
			query: `mutation{
        updateOffer(input:{id:"${id}", visibility:"${visibility}"}){
          offer{id}
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la modification de la visibilité de l'offre",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function modifyReferent(id: string, referent: string) {
	try {
		const queryToSend = {
			query: `mutation{
		updateOffer(input:{id:"/api/offers/${id}", responsable:"${referent}"}){
		  offer{id}
		}
	  }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la modification du référent de l'offre",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function modifyStatus(
	id: string,
	status: string,
	closeReason: string,
) {
	const reasonToShow =
		status !== "online" && status !== "draft"
			? `closeReason:"${closeReason}", ${
					closeReason ? `closeAt:"${new Date().toISOString()}"` : ""
				}`
			: "";

	try {
		const queryToSend = {
			query: `mutation{
        updateOffer(input:{id:"${id}", status:"${status}", ${reasonToShow}
      }){
          offer{id}
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la modification du statut de l'offre",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function modifyTags(id: string, tags: string[]) {
	try {
		const queryToSend = {
			query: `mutation{
        updateOffer(input:{id:"${id}", tags: [${tags.map((tag) => `"${tag}"`)}]
      }){
          offer{id}
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la modification des tags de l'offre",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function modifyCampus(id: string, campus: string[]) {
	const queryToSend = {
		query: `mutation{
        updateOffer(input:{id:"${id}", campuses: ${stringifyRequestInputs(
					campus,
				)}}){
          offer{
            id
            campuses{
              collection{
                id
              }
            }
          }
          
        }
      }`,
	};
	return graphqlRequestConstructor(
		queryToSend,
		"modifyCampusOfTheOffer",
		"Erreur lors de la modifications des campus de l'offre",
	);
}

export async function modifyOffer(offerData: Partial<SchoolOfferType>) {
	const {
		date,
		views,
		studentGetJob,
		crmCompany,
		campuses,
		selection,
		sentToCompanyCount,
		applicationsCount,
		applicationsToSend,
		applicationsToTreat,
		sharesCount,
		updateAt,
		responsable,
		status,
		visibility,
		...restOfferData
	} = offerData;
	const formattedData: any = {
		...restOfferData,
	};

	if (crmCompany?.name) {
		formattedData.crmCompanyName = crmCompany.name;
	}

	if (offerData?.entOffer) {
		formattedData.entOffer = offerData.entOffer?.id;
	}
	if (offerData?.pdf) {
		formattedData.pdf = offerData?.pdf["@id"] || offerData.pdf?.id;
	}

	formattedData.crmResponsableId = undefined;
	try {
		const queryToSend = {
			query: `mutation{
        updateOffer(input:${stringifyRequestInputs(formattedData)})
        {
          offer{
            id
            pdf {
              id
              filePath
              size
            }
          }
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la modification de l'offre",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function modifyOfferMemo(id: string, memo: string) {
	try {
		const escapedMemo = JSON.stringify(memo);

		const queryToSend = {
			query: `mutation{
        updateOffer(input:{id:"${id}", memo:${escapedMemo}}){
          offer{id}
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la modification du mémo de l'offre",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

type ShareOfferType = {
	offerId: string;
	sendEmail: boolean;
	sendSMS: boolean;
	students: string[];
};
export async function updateShareOffer(offerData: ShareOfferType) {
	const { offerId, sendEmail, sendSMS, students } = offerData;
	try {
		const queryToSend = {
			query: `mutation{
        updateOffer(input:
          {
            id:"${offerId}", 
            sendEmail:${sendEmail},
            sendSMS:${sendSMS},
            spsStudents: ${stringifyRequestInputs(students)}
          })
        {
          offer{id}
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(response, "Erreur lors du partage de l'offre");
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function modifyOfferProfilsSelection(
	id: string,
	selectionId: string,
) {
	try {
		const queryToSend = {
			query: `mutation{
        updateOffer(input:{id:"${id}", selection:"${selectionId}"}){
          offer{id}
        }
      }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la modification de la selection",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}
export async function removeProfilFromSelection(inputs: any) {
	try {
		const queryToSend = {
			query: `mutation{
		deleteSelectionUser(input:${inputs})
		{
            selectionUser{
                id
            }
        }
	  }`,
		};
		const response = await API.post("graphql", queryToSend);
		return checkGraphQLError(
			response,
			"Erreur lors de la suppression du profil de la selection",
		);
	} catch (error) {
		return {
			responseLabel: "error",
			message: error,
			data: {},
		};
	}
}

export async function getMailsLogForOffer(offerId: string) {
	const data = {
		query: `query {
      offer  (id: "${offerId}"){
        id
        logs (itemsPerPage: 4){
            collection{
                id
                description
            }
        }
      }
    }`,
	};
	return await graphqlRequestConstructor(data, "getMailsLogForOffer");
}
