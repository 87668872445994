import type {
	ApplicationStateType,
	ApplicationThumbType,
} from "@/types/applicationTypes";
import type { SchoolOfferType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";

import { plusOutline, refreshFill } from "@assets/Icons";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { SelectSecondary } from "@designSystem/organisms/selectSecondary/SelectSecondary";
import { BodyGrid } from "@designSystem/templates/page/BodyGrid";
import { Container } from "@designSystem/templates/page/Container";
import { Subcontainer } from "@designSystem/templates/page/Subcontainer";

import { setSendRelaunchInvitationWasClicked } from "@containers/school/Offers_v3/core/store/offersManagement.slice";
import {
	fetchOffersApplication,
	fetchOffersApplicationsThumbnails,
} from "@containers/school/Offers_v3/core/store/offersManagement.thunks";

import { createReferentApplicationsFromOffer } from "@containers/school/Offers_v3/core/api/offerApplication.request";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { StudentsTargetingModal } from "@modules/studentsTargetingModal";
import { success } from "@tools/Toasts";
import { useOutletContext } from "react-router";
import { ApplicationDataVisualizer } from "./ApplicationDataVisualizer";
import { ApplicationThumb } from "./ApplicationThumb";

const THUMBNAILS_BLOCK_TITLES_MAP = new Map<ApplicationStateType, string>([
	["update", "Mis à jour"],
	["toBeTreated", "Nouveaux"],
	["accepted", "Acceptés"],
	["onHold", "En attente"],
	["refused", "Refusés"],
]);

type ApplicationStatusFilterType = {
	value: string;
	label: string;
};

export function ApplicationsTab({
	offerToEdit,
}: {
	offerToEdit: Partial<SchoolOfferType>;
}) {
	const dispatchEvent = useDispatch();
	const { loadingStates, applicationsThumbnails, applicationSelectedId } =
		useAppSelector((state) => state.offers);
	const { structureView }: { structureView: object } = useOutletContext();
	const [showTargetingModal, setShowTargetingModal] = useState<boolean>(false);
	const [applicationStateFilter, setApplicationStateFilter] =
		useState<ApplicationStatusFilterType>({
			value: "",
			label: "Statut",
		});
	const callFetchThumbnails = () => {
		dispatchEvent(
			fetchOffersApplicationsThumbnails(offerToEdit?.id || "") as any,
		);
	};
	const callFetchSelectedApplication = () => {
		dispatchEvent(fetchOffersApplication(applicationSelectedId || "") as any);
	};

	const getThumbnailArrayFromApplicationState = (
		state: ApplicationStateType,
	) => {
		switch (state) {
			case "accepted":
				return applicationsThumbnails?.filter(
					(thumbnail: ApplicationThumbType) => thumbnail?.state === "accepted",
				);
			case "onHold":
				return applicationsThumbnails?.filter(
					(thumbnail: ApplicationThumbType) => thumbnail?.state === "onHold",
				);
			case "refused":
				return applicationsThumbnails?.filter(
					(thumbnail: ApplicationThumbType) => thumbnail?.state === "refused",
				);
			default:
				return applicationsThumbnails;
		}
	};

	const buildThumbnailsBlock = (stateForFilter: ApplicationStateType) => {
		const thumbnails = getThumbnailArrayFromApplicationState(stateForFilter);
		if (thumbnails?.length === 0) {
			return null;
		}
		return (
			<div className="mt-md flex flex-col items-start justify-start gap-sm">
				<p className="text-sm font-bold text-primary-700P">
					{THUMBNAILS_BLOCK_TITLES_MAP.get(stateForFilter)}(
					{
						thumbnails?.filter(
							(thumbnail: ApplicationThumbType) =>
								thumbnail.state === stateForFilter,
						).length
					}
					)
				</p>
				{thumbnails
					?.filter(
						(thumbnail: ApplicationThumbType) =>
							thumbnail.state === stateForFilter,
					)
					.map((thumbnail: ApplicationThumbType) => (
						<ApplicationThumb
							dispatchEvent={dispatchEvent}
							key={thumbnail?.id}
							thumbnailData={thumbnail}
							isSelected={applicationSelectedId === thumbnail?.id}
						/>
					))}
			</div>
		);
	};

	const buildAllThumbnailsBlock = () => {
		if (applicationsThumbnails?.length === 0) {
			return null;
		}
		return (
			<div className="mt-md flex flex-col items-start justify-start gap-sm">
				{applicationsThumbnails?.map((thumbnail: ApplicationThumbType) => (
					<ApplicationThumb
						dispatchEvent={dispatchEvent}
						key={thumbnail?.id}
						thumbnailData={thumbnail}
						isSelected={applicationSelectedId === thumbnail?.id}
					/>
				))}
			</div>
		);
	};

	const canBeShown = (state: string) => {
		if (offerToEdit?.type === "preselection") {
			return (
				applicationStateFilter.value === "" ||
				applicationStateFilter.value === state
			);
		}
		return false;
	};

	const getOptions = () => {
		const options: ApplicationStatusFilterType[] = [
			{ value: "", label: "Statut" },
			{ value: "update", label: "Mis à jour" },
			{ value: "toBeTreated", label: "Nouveaux" },
			{ value: "onHold", label: "En attente" },
			{ value: "accepted", label: "Acceptés" },
			{ value: "refused", label: "Refusés" },
		];
		return options;
	};

	useEffect(() => {
		if (applicationSelectedId) {
			callFetchSelectedApplication();
		}
	}, [applicationSelectedId]);

	useEffect(() => {
		callFetchThumbnails();
	}, [offerToEdit?.id]);
	return (
		<>
			<BodyGrid>
				{offerToEdit.applicationsCount === 0 ? (
					<div className="flex w-full flex-col items-center justify-center gap-md">
						<p className="text-xxsm font-bold text-primary-500">
							Vous n'avez aucun candidat.
						</p>
						{offerToEdit.type === "preselection" && (
							<ButtonPrimary
								icon={plusOutline}
								iconPlacement="left"
								label="Ajouter un étudiant"
								size="large"
								onClick={() => setShowTargetingModal(true)}
								disabled={offerToEdit.status === "draft"}
							/>
						)}
						<ButtonSecondary
							icon={refreshFill}
							iconDirection="left"
							label="Relancer l'offre"
							size="large"
							onClick={() =>
								dispatchEvent(setSendRelaunchInvitationWasClicked(true))
							}
							disabled={offerToEdit.status === "draft"}
						/>
						{offerToEdit?.status === "draft" && (
							<p className="text-xxsm font-bold text-primary-500">
								L'offre est actuellement en brouillon.
							</p>
						)}
					</div>
				) : (
					<>
						<Container
							side="left"
							reversedGrid
							overrideWidth={"w-80 !max-w-[312px]"}
						>
							<Subcontainer>
								<div className="max-h-[600px] min-h-[300px] overflow-y-auto scrollbar-hide">
									<div className="flex w-full gap-xsm">
										<p className="text-base font-normal uppercase text-primary-300">
											liste des candidats
										</p>
										<SelectSecondary
											options={getOptions()}
											onChange={(e) => {
												setApplicationStateFilter(e);
											}}
											defaultValue={applicationStateFilter}
											position="right"
											className="!min-w-[102px] [&>*]:min-w-[102px] flex-[1_1_100%]"
										/>
									</div>
									{loadingStates.isFetchingApplicationsThumbnail && (
										<Skeleton
											count={8}
											className="h-28 w-80 max-w-full rounded-md"
										/>
									)}
									{canBeShown("update") && buildThumbnailsBlock("update")}
									{canBeShown("toBeTreated") &&
										buildThumbnailsBlock("toBeTreated")}
									{canBeShown("onHold") && buildThumbnailsBlock("onHold")}
									{canBeShown("accepted") && buildThumbnailsBlock("accepted")}
									{canBeShown("refused") && buildThumbnailsBlock("refused")}
									{offerToEdit?.type !== "preselection" &&
										buildAllThumbnailsBlock()}
								</div>
								<div className="flex flex-col justify-start gap-xsm">
									<p className="text-xxsm font-bold text-primary-500">
										Pas assez de candidats ?
									</p>
									{offerToEdit.type === "preselection" && (
										<ButtonPrimary
											icon={plusOutline}
											iconPlacement="left"
											label="Ajouter un étudiant"
											className="!w-full"
											size="large"
											onClick={() => setShowTargetingModal(true)}
											disabled={offerToEdit.status === "draft"}
										/>
									)}
									<ButtonSecondary
										icon={refreshFill}
										iconDirection="left"
										label="Relancer l'offre"
										size="large"
										onClick={() =>
											dispatchEvent(setSendRelaunchInvitationWasClicked(true))
										}
										disabled={offerToEdit.status === "draft"}
									/>
									{offerToEdit?.status === "draft" && (
										<p className="text-xxsm font-bold text-primary-500">
											L'offre est actuellement en brouillon.
										</p>
									)}
								</div>
							</Subcontainer>
						</Container>
						<Container
							side="right"
							reversedGrid
							overrideWidth={"!w-full !max-w-full"}
						>
							{loadingStates.isFetchingApplication ? (
								<>
									<Subcontainer>
										<Skeleton
											count={1}
											className="h-96 min-h-full max-w-full rounded-md"
										/>
									</Subcontainer>
									<Subcontainer>
										<Skeleton
											count={1}
											className="h-96 min-h-full max-w-full rounded-md"
										/>
									</Subcontainer>
								</>
							) : (
								<Subcontainer>
									{applicationsThumbnails?.length !== 0 && (
										<ApplicationDataVisualizer />
									)}
								</Subcontainer>
							)}
						</Container>
					</>
				)}
			</BodyGrid>
			<StudentsTargetingModal
				show={showTargetingModal}
				onClose={() => {
					setShowTargetingModal(false);
				}}
				structureView={structureView}
				label="Sélectionner des étudiants qui n'ont pas candidaté à l'offre. (Étudiants qui ont un CV sur leur profil)"
				title="Ajouter des étudiants à l'offre"
				buttonLabel="Ajouter à l'offre"
				share={(_sendEmail, _sendSMS, selectedStudents) => {
					const studentsIds = selectedStudents.map((student) => student.id);
					createReferentApplicationsFromOffer(
						offerToEdit?.id || "",
						studentsIds,
					).then((response) => {
						if (response) {
							success("Les étudiants ont bien été ajoutés à l'offre");
						}
						// reload the page
						callFetchThumbnails();
					});
				}}
				showOptions={false}
				getStudentWithCv={true}
			/>
		</>
	);
}
