import type { Dependencies } from "@config/dependencies";
import type { AppDispatch, AppGetState } from "@config/redux/store";
import { extractError } from "@tools/API";
import { companiesManagementSlice } from "../store/companiesManagement.slice";

export const fetchCompanyOffers = async (
	dispatch: AppDispatch,
	api: AppGetState,
	dependencies: Dependencies,
) => {
	try {
		if (!dependencies.companyOffersGateway) {
			throw new Error("companyOffersGateway is not available in dependencies");
		}
		dispatch(companiesManagementSlice.actions.startFetchingCompanyOffers());
		const onlineOffers =
			await dependencies.companyOffersGateway?.getCompanyOffers(
				api().companiesManagement.targetCompany,
				"online",
			);
		dispatch(companiesManagementSlice.actions.addCompanyOffers(onlineOffers));

		dispatch(companiesManagementSlice.actions.handleFetchOffersStatus("draft"));
		const draftOffers =
			await dependencies.companyOffersGateway?.getCompanyOffers(
				api().companiesManagement.targetCompany,
				"draft",
			);
		dispatch(companiesManagementSlice.actions.addCompanyOffers(draftOffers));

		dispatch(companiesManagementSlice.actions.handleFetchOffersStatus("other"));
		const otherOffers =
			await dependencies.companyOffersGateway?.getCompanyOffers(
				api().companiesManagement.targetCompany,
				"other",
			);
		dispatch(companiesManagementSlice.actions.addCompanyOffers(otherOffers));

		dispatch(companiesManagementSlice.actions.completeFetchgingCompanyOffers());
	} catch (error: unknown) {
		console.error(error);
		dispatch(
			companiesManagementSlice.actions.handleOffersError(
				extractError(error).message.toString(),
			),
		);
	}
};
