import React from "react";

import { Contacts } from "../Contacts";
import { OrganizationDetail } from "../OrganizationDetail";

export function Organization({ application }) {
	return (
		<div className="mt-md flex pt-sm">
			<OrganizationDetail
				crmCompany={
					application?.offer?.crmCompany ?? application?.organizationV2
				}
			/>
			<Contacts listContacts={application.contacts.collection} />
		</div>
	);
}
