import API, { checkGraphQLError } from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";
import type { AxiosResponse } from "axios";

type generateAssistedGenerationResponseType = AxiosResponse<{
	message: string;
	spsIADataId: number;
}>;

export const generateAssistedGeneration = async (
	variables: Record<string, string>,
	spsIACardId: string,
): Promise<generateAssistedGenerationResponseType> => {
	try {
		return await API.post("assisted_generation", { ...variables, spsIACardId });
	} catch (error: any) {
		return error.response;
	}
};

export const getAssistedGenerationCards = async () => {
	const data = {
		query: `query {
      	     spsIACards (itemsPerPage: 1000, order: [{position: "ASC"}]){
      	     	collection {
      	     		id
      	     		name
      	     		description
      	     		color
      	     		tag
      	     		step
      	     		position
      	     		variablesArray
      	     	}
      	     }
    	}`,
	};
	try {
		const response = await API.post("graphql", data);
		return checkGraphQLError(response, "Les cartes ont été récupérées");
	} catch (error: any) {
		return error.response;
	}
};
export const AVAILABLE_FEEDBACKS = [
	"Trop répétitif ou redondant",
	"N'a pas suivi les instructions",
	"Manque de vocabulaire",
	"Ne prends pas en compte les informations fournies",
	"N'aime pas le style",
	"Manque de détails",
	"Pas assez personnel",
	"Réponses hors sujet",
] as const;

export type SpsIADataType = {
	id: string;
	isValidated?: boolean;
	feedback?: (typeof AVAILABLE_FEEDBACKS)[number] | null;
};

export const updateSpsIAData = async (inputs: SpsIADataType) => {
	const data = {
		query: `mutation {
            updateSpsIAData(input: ${stringifyRequestInputs(inputs)}) {
            	spsIAData {
                	id
            	}
            }
        }`,
	};
	try {
		const response = await API.post("graphql", data);
		return checkGraphQLError(
			response,
			"Votre réponse a bien été prise en compte",
		);
	} catch (error: any) {
		return error.response;
	}
};
