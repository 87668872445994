import { useEffect, useRef, useState } from "react";
import type {
	SelectOption,
	SelectPresenter,
	SelectPresenterProps,
} from "./SelectTypes";

export const useSelect = ({
	name,
	label,
	onSelectOption,
	tooltip,
	isRequired,
	options,
	useFullWidth,
	hasSearchbar,
	searchbarPlaceholder,
	preselectedOptionValue,
}: SelectPresenterProps): SelectPresenter => {
	const callSelectThisOption = (optionToSelect: SelectOption) => {
		setOptionSelected(optionToSelect.value);
		if (onSelectOption && typeof onSelectOption === "function") {
			onSelectOption(optionToSelect.value);
		}
		setIsOpen(false);
	};

	const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target) return;
		setSearch(e.target.value);
		if (e.target.value === "") {
			setFilteredOptions(options);
			return;
		}
		const updatedOptionsList = options.filter((option) =>
			option.label.toLowerCase().includes(search.toLowerCase()),
		);

		setFilteredOptions(updatedOptionsList);
	};

	const isSelected = (option: SelectOption): boolean => {
		return optionSelected?.toString()
			? optionSelected?.toString() === option.value.toString()
			: false;
	};

	const getOptionToShow = (): SelectOption => {
		let optionValueToCompare = optionSelected || preselectedOptionValue;
		if (typeof optionSelected === "number") {
			optionValueToCompare = optionSelected.toString();
		}
		if (optionValueToCompare) {
			const option = options.find(
				(option) => option.value.toString() === optionValueToCompare,
			);
			if (option) {
				return option;
			}
		}
		return {
			label: name,
			value: "",
		};
	};

	const handleOnBlur = (e: React.FocusEvent<HTMLDivElement>) => {
		if (
			selectV2Ref.current &&
			!selectV2Ref.current.contains(e.relatedTarget as Node)
		) {
			setIsOpen(false);
		}
	};

	const CHAR_WIDTH = 10;
	const MINIMAL_WIDTH = 250;
	const DEFAULT_WIDTH = 250;

	const getWidthOptionsList = () => {
		const maxOptionValueLength = Math.max(
			...options.map((option) => option.label.length),
		);
		const optionValueWidth = maxOptionValueLength * CHAR_WIDTH;
		if (optionValueWidth < MINIMAL_WIDTH) {
			return `${DEFAULT_WIDTH}px`;
		}
		return `${optionValueWidth > 320 ? 320 : optionValueWidth}px`;
	};

	const selectV2Ref = useRef<HTMLDivElement | null>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [optionSelected, setOptionSelected] = useState<string | number | null>(
		preselectedOptionValue || null,
	);

	const [search, setSearch] = useState<string>("");
	const [filteredOptions, setFilteredOptions] =
		useState<SelectOption[]>(options);

	useEffect(() => {
		setFilteredOptions(options);
	}, [options]);

	return {
		name,
		optionToShow: getOptionToShow(),
		label,
		tooltip,
		isRequired,
		options: filteredOptions,
		handleOnSearch,
		searchbarPlaceholder: searchbarPlaceholder || "Rechercher...",
		hasSearchbar,
		useFullWidth,
		isOpen,
		toggleIsOpen: () => setIsOpen(!isOpen),
		optionSelected: null,
		selectThisOption: callSelectThisOption,
		isSelected,
		selectV2Ref,
		handleOnBlur,
		listWidth: getWidthOptionsList(),
		search,
	};
};
