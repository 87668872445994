import type {
	ApplicationThumbType,
	ApplicationType,
} from "@/types/applicationTypes";
import type { FormattedApplicationDate } from "@/types/offerTypes";
import { extractError } from "@tools/API";
import { convertDateFromNow, diffDate, formatDate } from "@tools/Dates";
import { invariant } from "@tools/Invariant";
import { errorToast } from "@tools/Toasts";

export class OfferDetailsPresenter {
	formatApplicationDate(
		applicationData: ApplicationType | ApplicationThumbType,
	): FormattedApplicationDate {
		try {
			invariant(applicationData !== undefined, "undefined application data");
			invariant(
				applicationData !== null,
				"application data cannot be nullable",
			);
			invariant(
				"state" in applicationData,
				"application data must have a state property",
			);
			const formattingResult: FormattedApplicationDate = {
				prefix: "Il y a ",
				date: convertDateFromNow(applicationData.createdAt),
			};
			if (
				applicationData.state === "refused" &&
				"refusedDate" in applicationData
			) {
				formattingResult.date = convertDateFromNow(
					applicationData?.refusedDate ?? applicationData?.updateAt ?? "",
				);
			}

			if (
				applicationData.state === "accepted" &&
				"acceptedDate" in applicationData
			) {
				formattingResult.date = convertDateFromNow(
					applicationData?.acceptedDate ?? applicationData?.updateAt ?? "",
				);
			}

			if (
				applicationData.state === "onHold" &&
				"modificationRequestDate" in applicationData
			) {
				formattingResult.prefix = "Depuis ";
				formattingResult.date = convertDateFromNow(
					applicationData?.modificationRequestDate ??
						applicationData?.updateAt ??
						"",
				);
			}

			return formattingResult;
		} catch (error) {
			errorToast(extractError(error).message);
			return error;
		}
	}
	createDateMessageByTab = (
		selectedTab: string | undefined,
		applicationDate: ApplicationType | ApplicationThumbType | undefined,
	): string => {
		if (selectedTab === undefined || applicationDate === undefined) {
			return "";
		}
		return selectedTab === "sendToCompany"
			? `Sélectionné ${
					diffDate(applicationDate?.updateAt || "") !== 0
						? `il y a ${diffDate(applicationDate?.updateAt || "")}j`
						: "aujourd'hui"
				}`
			: `Reçue le ${formatDate(applicationDate?.createdAt || "")}`;
	};
}
