import { arrowDownOutline, arrowUpOutline, searchFill } from "@assets/Icons";
import { SystemIDProvider } from "@config/core/system.id-provider";
import { Checkbox } from "@designSystem/atoms/Checkbox";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { TooltipElement } from "@designSystem/atoms/TooltipElement";
import { Input } from "@designSystem/molecules/Input";
import { Icon } from "@iconify/react";
import React, { useContext } from "react";
import type { MultiselectV2Type } from "./MultiselectV2Types";
import { MultiselectV2Context, useMultiselectV2 } from "./useMultiselectV2";

/**
 * @description MultiselectV2 is a component that allows the user to select multiple options from a list.
 * @param {string} name - The name of the multiselect.
 * @param {Array<{label: string, value: string}>} options - The list of options.
 * @param {boolean} isRequired - If true, the multiselect will be required.
 * @param {Array<string>} preselectedOptionsValues - The list of values of the preselected options.
 * @param {function} onSelectOption - The function to call when an option is selected.
 * @param {boolean} hasSearchbar - If true, the multiselect will have a search bar.
 * @param {string} searchbarPlaceholder - The placeholder of the search bar.
 * @param {string} label - The label of the multiselect.
 * @param {string} tooltip - The tooltip of the multiselect.
 * @param {boolean} useFullWidth - If true, the multiselect will use the full width.
 * @returns {JSX.Element} - A MultiselectV2 component.
 */
export function MultiselectV2({
	name,
	options,
	preselectedOptionsValues = [],
	onSelectOption,
	hasSearchbar = false,
	searchbarPlaceholder = "Rechercher",
	label,
	isRequired,
	tooltip,
	useFullWidth = false,
}: MultiselectV2Type) {
	const presenter = useMultiselectV2({
		name,
		options,
		preselectedOptionsValues,
		onSelectOption,
		hasSearchbar,
		searchbarPlaceholder,
		label,
		isRequired,
		tooltip,
		useFullWidth,
	});

	return (
		<MultiselectV2Context.Provider value={{ presenter }}>
			<div
				ref={presenter.multiselectV2Ref}
				className={`relative select-none min-w-fit ${presenter.useFullWidth ? "w-full" : "max-w-fit"}`}
				id={`multiselect-${name}`}
				tabIndex={0}
				onBlur={presenter.handleOnBlur}
				role="listbox"
			>
				<MultiselectV2Label />
				<MultiselectV2Box />
				<MultiselectV2Dropdown />
			</div>
		</MultiselectV2Context.Provider>
	);
}

/**
 * @description MultiselectV2Label is a component that displays the label of the multiselect.
 * @returns {JSX.Element} - A MultiselectV2Label component.
 */
function MultiselectV2Label() {
	const idProvider = new SystemIDProvider();
	const tooltipId = idProvider.generate();
	const { presenter } = useContext(MultiselectV2Context);

	return (
		<div className={"flex gap-xsm mb-1 min-w-[200px] w-[200px]"}>
			<div className="text-xsm  text-primary-500">
				{presenter.label}
				<span className="text-alert-error-500 text-xxsm">
					{presenter.isRequired ? "*" : ""}
				</span>
			</div>
			{presenter.tooltip && (
				<>
					<TooltipElement id={`tooltip-multiselect-${tooltipId}`} />
					<TooltipContainer
						anchorId={`#tooltip-multiselect-${tooltipId}`}
						place="right"
					>
						{presenter.tooltip}
					</TooltipContainer>
				</>
			)}
		</div>
	);
}

/**
 * @description MultiselectV2Box is a component that displays the box of the multiselect.
 * @returns {JSX.Element} - A MultiselectV2Box component.
 */
function MultiselectV2Box() {
	const { presenter } = useContext(MultiselectV2Context);
	const selectedOptionsCount = presenter.selectedOptionsValues.length;

	return (
		<div
			className={`bg-white border rounded-md flex py-xsm px-sm cursor-pointer justify-between ${presenter.isOpen ? "border-accent-1-dark shadow-accent1Light" : "border-primary-150 hover:border-accent-1 hover:shadow-accent1Lighter"}`}
			onClick={() => {
				presenter.setIsOpen(!presenter.isOpen);
			}}
		>
			<div className="flex gap-xsm">
				{selectedOptionsCount > 0 && (
					<div className="px-xsm py-xxsm rounded-md border border-alert-info-turquoise-400 text-[10px] h-6 font-bold">
						{selectedOptionsCount}
					</div>
				)}
				<span className="text-base">{presenter.name}</span>
			</div>
			<div className="ml-2">
				<Icon
					className="h-6 text-primary-700P"
					icon={presenter.isOpen ? arrowUpOutline : arrowDownOutline}
				/>
			</div>
		</div>
	);
}

/**
 * @description MultiselectV2Dropdown is a component that displays the dropdown of the multiselect.
 * @returns {JSX.Element} - A MultiselectV2Dropdown component.
 */
function MultiselectV2Dropdown() {
	const { presenter } = useContext(MultiselectV2Context);

	return (
		presenter.isOpen && (
			<div
				className={`absolute flex flex-col gap-xxsm mt-xxsm ${presenter.useFullWidth ? "w-full" : "max-w-[320px]"} z-50`}
			>
				{presenter.hasSearchbar && (
					<Input
						name=""
						onChange={presenter.handleOnSearch}
						icon={searchFill}
						placeholder={presenter.searchbarPlaceholder}
						value=""
						useAutofocus={true}
					/>
				)}
				<MultiselectV2OptionsList />
			</div>
		)
	);
}

/**
 * @description MultiselectV2OptionsList is a component that displays the list of options of the multiselect.
 * @returns {JSX.Element} - A MultiselectV2OptionsList component.
 */
function MultiselectV2OptionsList() {
	const idProvider = new SystemIDProvider();
	const { presenter } = useContext(MultiselectV2Context);

	return (
		<div
			className={
				"overflow-y-auto bg-white shadow-md rounded-[4px] px-xxsm py-sm max-h-[280px]"
			}
			style={
				presenter.useFullWidth
					? undefined
					: { width: presenter.getWidthOptionsList() }
			}
		>
			{presenter.filteredOptions.map((option) => {
				const isChecked = presenter.selectedOptionsValues.includes(
					option.value,
				);
				return (
					<div
						className={`px-xsm py-xsm transition-all cursor-pointer flex items-center gap-xsm text-base min-h-12
						hover:bg-primary-100
						`}
						key={`option-${idProvider.generate()}`}
						onClick={() => {
							presenter.handleSelectOption(option.value);
						}}
					>
						<div className="w-fit flex justify-center items-center">
							<Checkbox checked={isChecked} />
						</div>
						<span className={isChecked ? "text-xsm font-bold" : ""}>
							{option.label}
						</span>
					</div>
				);
			})}
		</div>
	);
}
