import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useNavigate, useOutletContext } from "react-router";

import { ScrollableTabGroup } from "@designSystem/molecules/TabGroup";
import { JobboardsOffers } from "./Body/JobboardsOffers";
import { SchoolOffers } from "./Body/SchoolOffers";

export function Body() {
	const navigate = useNavigate();
	const { currentInterface }: { currentInterface: string } = useOutletContext();
	const {
		load,
		tab,
		totalSchoolCount,
		totalScrapedCount,
		loadSchoolOffers,
		loadScrapedOffers,
	} = useAppSelector((state) => state.betaJobboard);
	return (
		<section className="col-span-4 md:col-span-8 lg:col-span-12">
			<ScrollableTabGroup
				isLoading={load}
				tabs={[
					{
						label: `Offres École (${
							loadSchoolOffers || loadScrapedOffers ? "..." : totalSchoolCount
						})`,
						active: tab === "schoolOffers",
					},
					{
						label: `Offres (${
							loadSchoolOffers || loadScrapedOffers ? "..." : totalScrapedCount
						})`,
						active: tab === "jobboardsOffers",
					},
				]}
				onTabChange={(i) => {
					switch (i) {
						case 1:
							navigate(
								currentInterface === "student"
									? "/beta-jobboard"
									: "/beta-school-jobboard",
							);
							break;
						default:
							navigate(
								currentInterface === "student"
									? "/beta-school-offers"
									: "/beta-own-offers",
							);
							break;
					}
				}}
			/>
			<div className="mt-sm">
				{tab === "jobboardsOffers" ? <JobboardsOffers /> : <SchoolOffers />}
			</div>
		</section>
	);
}
