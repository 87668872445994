import { useAppSelector } from "@config/redux/hook";
import type { JobboardSliceState } from "@containers/student/BetaJobboard/core/store/betaJobboard.slice";
import { SearchHistoryItem } from "@containers/student/BetaJobboard/ui/jobboard/ui/SearchHistory/SearchHistoryItem";
import { Spinner } from "@designSystem/atoms/Spinner";
import React from "react";

export const SearchHistory = () => {
	const { searchHistory, loadSearchHistory } =
		useAppSelector<JobboardSliceState>((state) => state.betaJobboard);
	if (searchHistory.length === 0)
		return <p className="text-center">Aucune recherche pour le moment</p>;
	if (loadSearchHistory)
		return (
			<div className="flex items-center flex-col justify-center">
				<Spinner />
				<p>Chargement en cours...</p>
			</div>
		);
	return (
		<div className="flex flex-1 flex-col gap-sm py-sm">
			{searchHistory.map((searchHistoryItem) => (
				<SearchHistoryItem
					searchHistoryItem={searchHistoryItem}
					key={searchHistoryItem.id}
				/>
			))}
		</div>
	);
};
