import React from "react";

import {
	checkmarkOutline,
	closeOutline,
	questionMarkOutline,
} from "@assets/Icons";

import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { AcceptApplicationModal } from "../../../modal/AcceptApplicationModal";
import { OnHoldApplicationModal } from "../../../modal/OnHoldApplicationModal";
import { RefuseApplicationModal } from "../../../modal/RefuseApplicationModal";
import { RemoveProfilButton } from "../sendToCompany/RemoveProfilButton";

import { OfferStudentProfilCard } from "./ApplicationDataVisualizer/OfferStudentProfilCard";
import { ApplicationDocumentsReader } from "./ApplicationDocumentsReader";
import { ApplicationMessages } from "./ApplicationMessages";
import { ApplicationStep } from "./ApplicationStep";
import { useApplicationDataVisualizer } from "./useApplicationDataVisualizer";

export function ApplicationDataVisualizer() {
	const presenter = useApplicationDataVisualizer();

	return (
		<section className="flex flex-col gap-8">
			<article className="flex w-full justify-between">
				<header>
					<p className="text-sm font-bold text-primary-700P">
						Candidature de
						{presenter.identityToShow}
					</p>
					<p className="text-xxsm font-semibold text-primary-200">
						{presenter.date}
					</p>
				</header>
				{presenter.showSendToCompanyOptions && (
					<aside className="flex flex-col items-center">
						<p className="mr-xsm text-xsm font-bold text-primary-200">
							Profil intéressant ?
						</p>
						<div className="flex items-center gap-0">
							<ButtonTertiary
								active={presenter?.state === "accepted"}
								onClick={() => {
									presenter.selectModalData("accept");
									presenter.toggleModal("accept");
								}}
								className="ml-sm"
								icon={checkmarkOutline}
								activeIcon={checkmarkOutline}
							/>
							<ButtonTertiary
								active={presenter?.state === "onHold"}
								onClick={() => {
									presenter.selectModalData("maybe");
									presenter.toggleModal("onHold");
								}}
								className="ml-sm"
								icon={questionMarkOutline}
								activeIcon={questionMarkOutline}
							/>
							<ButtonTertiary
								active={presenter?.state === "refused"}
								onClick={() => presenter.toggleShowRefuseModal("application")}
								className="ml-sm"
								icon={closeOutline}
								activeIcon={closeOutline}
							/>
						</div>
					</aside>
				)}
				{presenter.showRemoveProfilButton && (
					<RemoveProfilButton
						onClick={() => presenter.toggleShowRefuseModal("selection")}
					/>
				)}
			</article>
			{presenter.showApplicationStep && (
				<ApplicationStep step={presenter.step} />
			)}
			{presenter.showApplicationMessages && <ApplicationMessages />}
			<OfferStudentProfilCard />
			<ApplicationDocumentsReader />
			{presenter?.currentModalData && (
				<>
					<AcceptApplicationModal
						show={presenter.showAcceptModal}
						onClose={() => {
							return presenter.toggleModal("accept");
						}}
						currentModalDataProps={{
							currentModalData: presenter?.currentModalData,
						}}
						edit={presenter.acceptThisApplication}
					/>

					<OnHoldApplicationModal
						show={presenter.showMaybeModal}
						onClose={() => {
							return presenter.toggleModal("onHold");
						}}
						state={presenter.state}
						treatmentProps={presenter.treatmentReasonState}
						messagesProps={presenter.messagesState}
						edit={presenter.onHoldThisApplication}
					/>

					<RefuseApplicationModal
						show={presenter.showRefusedModal}
						onClose={() => {
							presenter.setShowRefusedModal(false);
						}}
						from={presenter.refusedFrom}
						state={presenter.state}
						treatmentProps={presenter.treatmentReasonState}
						messagesProps={presenter.messagesState}
						edit={presenter.refuseThisApplication}
					/>
				</>
			)}
		</section>
	);
}
