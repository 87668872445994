import { useAppSelector } from "@/config/redux/hook";
import { useAppDispatch } from "@/config/redux/store";
import { updateApplication } from "@/containers/school/Offers_v3/core/api/offerApplication.request";
import { OfferDetailsPresenter } from "@/containers/school/Offers_v3/core/model/offerDetails.presenter";
import {
	type OffersManagementState,
	modifyOfferToEdit,
	setOfferToEdit,
} from "@/containers/school/Offers_v3/core/store/offersManagement.slice";
import {
	fetchOffersApplication,
	fetchOffersApplicationsThumbnails,
} from "@/containers/school/Offers_v3/core/store/offersManagement.thunks";
import { loadingToast, success, updateToastWithSuccess } from "@/tools/Toasts";
import type { ApplicationStepType } from "@/types/applicationTypes";
import type { UserType } from "@/types/userTypes";
import { useState } from "react";
import { DATA_APP_DETAILS } from "../../../modal/ApplicationModalsData";

export const useApplicationDataVisualizer = () => {
	const selectModalData = (key: "accept" | "maybe" | "decline") => {
		setCurrentModalData(DATA_APP_DETAILS.modals[key]);
	};

	const toggleShowRefuseModal = (from: "application" | "selection") => {
		selectModalData("decline");
		setShowRefusedModal(from);
	};

	const canIShowApplicationMessages = (): boolean => {
		return (
			applicationSelectedData?.state === "update" ||
			applicationSelectedData?.state === "onHold"
		);
	};

	const canIShowRemoveProfilButton = (): boolean => {
		const sendToCompany = currentDetailTabSelected === "sendToCompany";
		const userIsInSelection =
			offerToEdit?.selection?.selectionUsers.collection.find(
				({ user }: { user: Partial<UserType> }) =>
					applicationSelectedData?.spsStudent?.id === user.id,
			) !== undefined || false;
		return sendToCompany && userIsInSelection;
	};

	const toggleModal = (modal: "accept" | "onHold") => {
		if (modal === "accept") {
			setShowAcceptModal(!showAcceptModal);
		} else {
			setShowMaybeModal(!showMaybeModal);
		}
	};

	const editApplicationResponse = async (
		stateValue: string,
		treatmentReason: string | undefined = undefined,
		messages: string[] | undefined = undefined,
	) => {
		const toast = loadingToast("Traitement en cours...");
		const oldStatus = applicationSelectedData?.isTreated;
		const response = await updateApplication({
			id: applicationSelectedData?.id || "",
			state: stateValue,
			isTreated: true,
			treatmentReason: treatmentReason || null,
			messages,
			operation: "treat",
		});

		if (response.responseLabel === "success") {
			dispatchEvent(
				fetchOffersApplicationsThumbnails(offerToEdit?.id || "") as any,
			);
			dispatchEvent(
				fetchOffersApplication(applicationSelectedData?.id || "") as any,
			);
			if (offerToEdit && !oldStatus) {
				const newOfferToEdit = { ...offerToEdit };
				newOfferToEdit.applicationsToTreat -= 1;
				dispatchEvent(setOfferToEdit(newOfferToEdit));
			}
			updateToastWithSuccess(toast, "La candidature a bien été modifiée");
		}
	};

	const removeProfilToSelection = async () => {
		if (
			offerToEdit?.id &&
			applicationSelectedData?.spsStudent?.id &&
			offerToEdit?.selection?.id
		) {
			const selectionUsers =
				offerToEdit.selection.selectionUsers.collection.filter(
					({ user }: { user: Partial<UserType> }) =>
						user.id !== applicationSelectedData?.spsStudent?.id,
				);
			dispatchEvent(
				modifyOfferToEdit({
					selection:
						selectionUsers.length === 0
							? null
							: {
									...offerToEdit.selection,
									selectionUsers: {
										collection: selectionUsers,
									},
								},
				}),
			);
			dispatchEvent(
				fetchOffersApplicationsThumbnails(offerToEdit?.id || "") as any,
			);
			success("Profil retiré de l'envoi");
		}
	};

	const acceptThisApplication = () => {
		editApplicationResponse(currentModalData?.confirmValue);
	};

	const onHoldThisApplication = () => {
		editApplicationResponse(
			currentModalData?.confirmValue,
			treatmentReason || "",
			messages,
		);
	};

	const refuseThisApplication = async () => {
		await removeProfilToSelection();
		editApplicationResponse(
			currentModalData?.confirmValue,
			treatmentReason || "",
			messages,
		);
	};

	const [currentModalData, setCurrentModalData] = useState<any>(
		DATA_APP_DETAILS.modals.accept,
	);

	const [showRefusedModal, setShowRefusedModal] = useState<
		false | "application" | "selection"
	>(false);

	const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false);
	const [showMaybeModal, setShowMaybeModal] = useState<boolean>(false);

	const [treatmentReason, setTreatmentReason] = useState<string>("");
	const [messages, setMessages] = useState([""]);
	const dispatchEvent = useAppDispatch();
	const { applicationSelectedData, offerToEdit, currentDetailTabSelected } =
		useAppSelector((state) => state.offers as OffersManagementState);

	const presenter = new OfferDetailsPresenter();
	return {
		currentModalData,
		selectModalData,
		toggleShowRefuseModal,
		showRefusedModal: showRefusedModal !== false,
		setShowRefusedModal,
		refusedFrom: showRefusedModal || "application",
		showApplicationMessages: canIShowApplicationMessages(),
		showRemoveProfilButton: canIShowRemoveProfilButton(),
		showSendToCompanyOptions:
			currentDetailTabSelected !== "sendToCompany" &&
			offerToEdit?.type === "preselection",
		showApplicationStep:
			currentDetailTabSelected !== "sendToCompany" &&
			offerToEdit?.type === "direct",
		showMaybeModal,
		showAcceptModal,
		toggleModal,
		identityToShow: ` ${applicationSelectedData?.spsStudent?.firstname} ${applicationSelectedData?.spsStudent?.lastname}`,
		date: presenter.createDateMessageByTab(
			currentDetailTabSelected,
			applicationSelectedData,
		),
		state: applicationSelectedData?.state || "",
		step: (applicationSelectedData?.step as ApplicationStepType) || "",
		treatmentReasonState: {
			treatmentReason,
			setTreatmentReason,
		},
		messagesState: {
			messages,
			setMessages,
		},
		acceptThisApplication,
		onHoldThisApplication,
		refuseThisApplication,
	};
};
