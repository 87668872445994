import type { CompaniesCellNameType } from "@/types/companiesManagement.types";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { DEFAULT_COMPANIES_TABLE_CONFIG } from "@constants/Company";

import { getCookie } from "@tools/Cookies";

import type { CampusType } from "@/types/campusTypes";
import type { ReferentType } from "@/types/userTypes";
import {
	removeDuplicateReferents,
	sortReferentsByLastName,
} from "@tools/Offers";
import { errorToast } from "@tools/Toasts";
import type { CrmCompanyDomainModel } from "../../../../../types/crmCompany.domainModel";
import {
	SETTINGS_COOKIE_NAME,
	fetchCampuses,
	fetchCompaniesList,
	fetchReferents,
} from "./companiesManagement.thunks";

const INITIAL_CREATION_MODAL_STATE: Partial<CrmCompanyDomainModel.Company> = {
	relation: 0,
};

type CompaniesManagementState = {
	isInitialized: boolean;
	companiesTableConfig: CompaniesCellNameType[];
	currentSearch: string;
	companiesList: CrmCompanyDomainModel.Company[];
	filteredCompaniesList: CrmCompanyDomainModel.Company[];
	numberOfCompanies: number;
	paginationState: {
		pageShown: number;
		itemsPerPage: number;
		lastPage: number;
		order: string;
	};
	loadingStates: {
		isFetchingCompanies: boolean;
		isFetchingCampuses: boolean;
		isFetchingReferents: boolean;
	};
	companyToCreate: Partial<CrmCompanyDomainModel.Company> | undefined;
	campusesListReceived?: CampusType[];
	referentsListReceived?: {
		campusReferents: ReferentType[];
		schoolReferents: ReferentType[];
	};
	companyNameAlreadyExist?: boolean;
	companySiretAlreadyExist?: boolean;
	dataWasModified: boolean;
	companyDetails: CrmCompanyDomainModel.CompanyDetails;
	targetCompany: string | null;
	detailPageState: CrmCompanyDomainModel.DetailPageState;
};

const getSettingsCookie = () => {
	const cookie = getCookie(SETTINGS_COOKIE_NAME);
	if (cookie) {
		return JSON.parse(cookie) as CompaniesCellNameType[];
	}
	return DEFAULT_COMPANIES_TABLE_CONFIG;
};

const initialState: CompaniesManagementState = {
	isInitialized: false,
	companiesTableConfig: getSettingsCookie(),
	currentSearch: "",
	companiesList: [],
	filteredCompaniesList: [],
	numberOfCompanies: 0,
	paginationState: {
		pageShown: 1,
		itemsPerPage: 10,
		lastPage: 1,
		order: `[{ createdAt: "DESC" }]`,
	},
	loadingStates: {
		isFetchingCompanies: false,
		isFetchingCampuses: false,
		isFetchingReferents: false,
	},
	companyToCreate: INITIAL_CREATION_MODAL_STATE,
	campusesListReceived: [],
	companyNameAlreadyExist: false,
	companySiretAlreadyExist: false,
	dataWasModified: false,
	detailPageState: {
		isExtractingId: true,
		isFetchingInformations: false,
		isFetchingOffers: false,
	},
	companyDetails: {
		status: {
			informations: "idle",
			offers: "idle",
			relations: "idle",
			memo: "idle",
			contact: "idle",
		},
		informations: null,
		relations: null,
		offers: null,
		contact: null,
		memo: "",
	},
	targetCompany: null,
};

export const companiesManagementSlice = createSlice({
	name: "companiesManagement",
	initialState,
	reducers: {
		startFetchingCompanyOffers(state) {
			state.companyDetails.offers = initialState.companyDetails.offers;
			state.companyDetails.status.offers = "online";
		},
		setCompaniesTableConfig(state, action) {
			state.companiesTableConfig = action.payload;
		},
		setCurrentPage(state, action) {
			state.paginationState.pageShown = action.payload;
		},
		setItemsPerPage(state, action) {
			state.paginationState.itemsPerPage = action.payload;
		},
		setOrder(state, action) {
			state.paginationState.order = action.payload;
		},
		modifyCompanyToCreate(state, action) {
			state.companyToCreate = {
				...state.companyToCreate,
				...action.payload,
			};
		},
		modifyCompanyInformation(state, action) {
			state.companyDetails.informations = {
				...state.companyDetails.informations,
				...action.payload,
			};
			state.companyDetails.status.informations = "success";
		},
		handleMemoLoading(state) {
			state.companyDetails.status.memo = "loading";
		},
		handleMemoError(state, action: PayloadAction<string>) {
			errorToast(action.payload);
			state.companyDetails.status.memo = "error";
		},
		modifyCompanyMemo(state, action) {
			state.companyDetails.memo = action.payload;
			state.companyDetails.status.memo = "success";
		},
		handleInformationsLoading(state) {
			state.companyDetails.status.informations = "loading";
		},
		handleInformationsError(state, action: PayloadAction<string>) {
			errorToast(action.payload);
			state.companyDetails.status.informations = "error";
		},
		handleUpdateInformationsError(_state, action: PayloadAction<string>) {
			errorToast(action.payload);
		},
		handleUpdateContactError(_state, action: PayloadAction<string>) {
			errorToast(action.payload);
		},
		handleUpdateRelationsError(_state, action: PayloadAction<string>) {
			errorToast(action.payload);
		},
		handleFetchOffersStatus(
			state,
			action: PayloadAction<CrmCompanyDomainModel.CompanyOffersStatus>,
		) {
			state.companyDetails.status.offers = action.payload;
		},
		handleOffersError(state, action: PayloadAction<string>) {
			errorToast(action.payload);
			state.companyDetails.status.offers = "error";
		},
		handleRelationsLoading(state) {
			state.companyDetails.status.relations = "loading";
		},
		handleRelationsError(state, action: PayloadAction<string>) {
			errorToast(action.payload);
			state.companyDetails.status.relations = "error";
		},
		modifyCompanyRelations(state, action) {
			const coreCampuses =
				action?.payload?.coreCampuses?.collection?.map(
					(campus: CampusType) => campus.id,
				) || [];
			state.companyDetails.relations = {
				...state.companyDetails.relations,
				...action.payload,
				coreCampuses,
			};
			state.companyDetails.status.relations = "success";
		},
		handleContactLoading(state) {
			state.companyDetails.status.contact = "loading";
		},
		handleContactError(state, action: PayloadAction<string>) {
			errorToast(action.payload);
			state.companyDetails.status.contact = "error";
		},
		modifyCompanyContact(state, action) {
			state.companyDetails.contact = action.payload;
			state.companyDetails.status.contact = "success";
		},
		modifyCompanyOffers(state, action) {
			state.companyDetails.offers = action.payload;
		},
		addCompanyOffers(state, action) {
			state.companyDetails.offers = [
				...(state.companyDetails.offers || []),
				...action.payload,
			];
		},
		completeFetchgingCompanyOffers(state) {
			state.companyDetails.status.offers = "success";
		},
		modifyDetailPageState(state, action) {
			state.detailPageState = {
				...state.detailPageState,
				...action.payload,
			};
		},
		creationModalWasClosed(state) {
			state.companyToCreate = INITIAL_CREATION_MODAL_STATE;
			state.companyNameAlreadyExist = false;
		},
		updateReferentList(state, action) {
			state.referentsListReceived = action.payload;
		},
		setCompanyAlreadyExist(state, action) {
			state.companyNameAlreadyExist = action.payload;
		},
		setCompanySiretAlreadyExist(state, action) {
			state.companySiretAlreadyExist = action.payload;
		},
		setDataWasModified(state, action) {
			state.dataWasModified = action.payload;
		},
		targetThisCompany(state, action) {
			state.targetCompany = action.payload;
		},
		endOfCompanyIdExtraction(state, action: PayloadAction<string>) {
			state.targetCompany = action.payload;
			state.detailPageState.isExtractingId = false;
		},
		resetDetailsState(state) {
			state.companyDetails = initialState.companyDetails;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchCompaniesList.pending, (state) => {
			state.loadingStates.isFetchingCompanies = true;
		});
		builder.addCase(fetchCompaniesList.fulfilled, (state, action) => {
			state.loadingStates.isFetchingCompanies = false;
			state.companiesList = action.payload.collection;
			state.filteredCompaniesList = action.payload.collection;
			state.numberOfCompanies = action.payload.paginationInfo.totalCount;
			state.paginationState.lastPage = action.payload.paginationInfo.lastPage;
			state.isInitialized = true;
		});
		builder.addCase(fetchCompaniesList.rejected, (state) => {
			state.loadingStates.isFetchingCompanies = false;
		});
		builder.addCase(fetchCampuses.pending, (state) => {
			state.campusesListReceived = undefined;
			state.loadingStates.isFetchingCampuses = true;
		});
		builder.addCase(fetchCampuses.rejected, (state) => {
			state.loadingStates.isFetchingCampuses = false;
		});
		builder.addCase(fetchCampuses.fulfilled, (state, action) => {
			state.campusesListReceived = action.payload;
			state.loadingStates.isFetchingCampuses = false;
		});
		builder.addCase(fetchReferents.pending, (state) => {
			state.referentsListReceived = undefined;
			state.loadingStates.isFetchingReferents = true;
		});
		builder.addCase(fetchReferents.rejected, (state, action) => {
			if (action.error.message !== "aborted") {
				errorToast(
					"Une erreur est survenue lors de la récupération des référents.",
				);
				state.loadingStates.isFetchingReferents = false;
			}
		});
		builder.addCase(fetchReferents.fulfilled, (state, action) => {
			const newReferentsList = {
				campusReferents: sortReferentsByLastName(
					removeDuplicateReferents(action.payload.campusReferents.collection),
				),
				schoolReferents: sortReferentsByLastName(
					removeDuplicateReferents(action.payload.schoolReferents.collection),
				),
			};
			state.referentsListReceived = newReferentsList;
			state.loadingStates.isFetchingReferents = false;
		});
	},
});

export const companiesManagemenReducer = companiesManagementSlice.reducer;
export const companiesManagemenActions = companiesManagementSlice.actions;
