import type { CityType } from "@/types/LocationTypes";
import { arrowBackOutline, refreshOutline, searchOutline } from "@assets/Icons";
import { useAppSelector } from "@config/redux/hook";
import { CONTRACT_TYPE_OPTIONS, DISTANCE_OPTIONS } from "@constants/Offers";
import {
	type JobboardSliceState,
	setShowCreateSearchModal,
	setShowSearchesModal,
} from "@containers/student/BetaJobboard/core/store/betaJobboard.slice";
import { createHistorySearch } from "@containers/student/BetaJobboard/core/store/betaJobboard.thunks";
import type { SearchHistoryType } from "@containers/student/BetaJobboard/core/types/jobboard.types";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { Spinner } from "@designSystem/atoms/Spinner";
import { Input } from "@designSystem/molecules/Input";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { SelectLocation } from "@designSystem/organisms/select/SelectLocation";
import { SelectV2 } from "@designSystem/organisms/selectV2/SelectV2";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

export const CreateSearchModal = () => {
	const dispatchJobboard = useDispatch();
	const { showCreateSearchModal, loadSearchHistory } =
		useAppSelector<JobboardSliceState>((state) => {
			return state.betaJobboard;
		});
	const [search, setSearch] = useState<string>("");
	const [contract, setContract] = useState<string | null>(null);
	const [maxDistance, setMaxDistance] = useState<number | null>(null);

	const [locationForFilter, setLocationForFilter] = useState<CityType | null>();

	const handleCreateSearch = async () => {
		const data = {
			search,
			contract,
			distance: maxDistance,
			city: locationForFilter?.location,
		} as Partial<SearchHistoryType>;
		if (locationForFilter?.latitude && locationForFilter?.longitude) {
			data.latitude = locationForFilter.latitude;
			data.longitude = locationForFilter.longitude;
		}
		dispatchJobboard(createHistorySearch(data) as any);
	};
	return (
		<Modal
			title={"Ma recherche"}
			show={showCreateSearchModal}
			onClose={() => {
				dispatchJobboard(setShowSearchesModal(true));
				dispatchJobboard(setShowCreateSearchModal(false));
			}}
			body={
				loadSearchHistory ? (
					<div className="flex items-center flex-col justify-center">
						<Spinner />
						<p>Création en cours...</p>
					</div>
				) : (
					<div className="flex w-full flex-col gap-sm rounded-lg bg-white">
						<InlineButton
							icon={arrowBackOutline}
							label="Retour"
							onClick={() => {
								dispatchJobboard(setShowSearchesModal(true));
								dispatchJobboard(setShowCreateSearchModal(false));
							}}
						/>
						<div className="flex w-full gap-sm">
							<Input
								placeholder="Recherche un métier"
								icon={searchOutline}
								value={search}
								onChange={(e) => {
									setSearch(e.target.value.replace(";", ""));
								}}
								className="w-full"
							/>
						</div>
						<div className="flex w-full flex-col gap-sm">
							<SelectV2
								options={CONTRACT_TYPE_OPTIONS}
								preselectedOptionValue={contract as string}
								onSelectOption={(value) => {
									setContract(value as string);
								}}
								useFullWidth={true}
								name={"Type d'offre"}
							/>
							<div className="flex gap-xsm items-center">
								<SelectLocation
									withLabel={false}
									handleNewLocation={setLocationForFilter}
									defaultValue={locationForFilter}
									className="flex-1"
									placeholder="Localisation"
								/>
								<SelectV2
									name="Distance"
									options={DISTANCE_OPTIONS}
									preselectedOptionValue={maxDistance as number}
									onSelectOption={(value) => {
										setMaxDistance(value as number);
									}}
								/>
							</div>
							<InlineButton
								icon={refreshOutline}
								onClick={() => {
									setMaxDistance(null);
									setLocationForFilter(null);
									setContract(null);
									setSearch("");
								}}
								label="Réinitialiser la recherche"
							/>
						</div>
					</div>
				)
			}
			buttonsRight={[
				<ButtonPrimary
					label="Rechercher"
					disabled={loadSearchHistory}
					onClick={handleCreateSearch}
				/>,
			]}
		/>
	);
};
