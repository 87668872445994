import type { ApplicationType } from "@/types/applicationTypes";
import API, { extractError } from "@tools/API";

import type { SearchHistoryType } from "@containers/student/BetaJobboard/core/types/jobboard.types";
import api, {
	checkGraphQLError,
	graphqlRequestConstructor,
	type StandardGraphQLResponse,
} from "@tools/API";
import { stringifyRequestInputs } from "@tools/Objects";
import { success } from "@tools/Toasts";
import algoliasearch from "algoliasearch/lite";

const client = algoliasearch(
	import.meta.env.VITE_ALGOLIA_ID ?? "",
	import.meta.env.VITE_ALGOLIA_KEY ?? "",
);

const index = client.initIndex(import.meta.env.VITE_ALGOLIA_OFFER_INDEX ?? "");

export async function importOffer(id: string, isMentorGoal = true) {
	try {
		const { data } = await api.post(
			`create_application_from${isMentorGoal ? "_mentorgoal" : ""}_offer/${id}`,
		);
		return data;
	} catch (_error) {
		const responseToReturnIfError: StandardGraphQLResponse = {
			responseLabel: "error",
			data: {},
			message: "Une erreur est survenue",
		};
		return responseToReturnIfError;
	}
}

export async function retrieveSchoolOffers(filters: string) {
	const query = {
		query: `{
      collectionQueryOffers(${filters}, order: [{date: "DESC"}], status: "online" ) {
        collection {
          id
          job
					displayCompanyName
          location
          contract
          date
        },
        paginationInfo {
          totalCount
          lastPage
        }
      },
    
    }`,
	};

	const responseToQuery = await graphqlRequestConstructor(
		query,
		"retrieveSchoolOffers",
		"Récupération des offres impossible",
	);

	return responseToQuery;
}

export async function retrieveScrapedOffers(
	search: string,
	algoliaFilters: object,
) {
	const { hits, nbHits, nbPages } = await index.search(
		search ?? "",
		algoliaFilters,
	);

	const responseToReturn: StandardGraphQLResponse = {
		responseLabel: "success",
		data: {
			collectionQueryOffers: {
				collection: hits,
				paginationInfo: {
					totalCount: nbHits,
					lastPage: nbPages,
				},
			},
		},
		message: "request successful",
	};

	return responseToReturn;
}

export async function getSchoolOffersCount(filters: string) {
	let rep = "...";

	const data = {
		query: `{
      collectionQueryOffers(${filters}, order: [{date: "DESC"}], status: "online") {
        paginationInfo {
          totalCount
        }
      },
    
    }`,
	};

	try {
		rep = await api.post("graphql", data);
		return checkGraphQLError(rep, "request successful");
	} catch (error) {
		throw new Error(error);
	}
}

export async function retrieveApplications(userId: string) {
	let rep: ApplicationType[] = [];

	const data = {
		query: `{
      applications(spsStudent: "${userId}", itemsPerPage: 10000) {
        collection {
          id
          url: offerLink
          offer {
            id
          }
        },
      },
    }`,
	};

	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.applications.collection;
	});
	return rep;
}

export async function editApplication(inputs: object) {
	let rep = {};
	const data = {
		query: `mutation {
        updateApplication(input: ${stringifyRequestInputs(inputs)}) {
          application {
            id
          }
        }
      }`,
	};
	await api.post("graphql", data).then(({ data }) => {
		rep = data.data.updateApplication;
	});
	return rep;
}

export async function signalThisOffer(offerId: string) {
	try {
		const data = await API.post(`report_mg_offer/${offerId}`);
		if (data) {
			success("L'offre a bien été signalée");
		}
	} catch (error: unknown) {
		throw extractError(error);
	}
}

export async function getStudentOffersSearches(userId: string) {
	const data = {
		query: `
        query {
          spsStudent(id: "${userId}") {
			spsOfferSearch {
				collection {
					id
					search
					contract
					distance
					longitude
					latitude
					city
				}
			}
          },
        }`,
	};
	try {
		const response = await API.post("graphql", data);
		return checkGraphQLError(
			response,
			"Votre historique de recherche a été récupéré",
		);
	} catch (error: any) {
		return error.response;
	}
}

export async function deleteOfferSearch(uri: string) {
	const data = {
		query: `
		mutation{
			deleteSpsOfferSearch(input: {id:"${uri}"}){
				spsOfferSearch {
					id
			  	}
			}
		}`,
	};
	try {
		const response = await API.post("graphql", data);
		return checkGraphQLError(response, "La recherche a été supprimée");
	} catch (error: any) {
		return error.response;
	}
}

export async function createOfferSearch(
	offerSearch: Partial<SearchHistoryType>,
) {
	const data = {
		query: `mutation {
        createSpsOfferSearch(input: ${stringifyRequestInputs(offerSearch)}) {
          spsOfferSearch {
            id
			search
			contract
			distance
			longitude
			latitude
			city
          }
        }
      }`,
	};
	try {
		const response = await API.post("graphql", data);
		return checkGraphQLError(response, "La recherche a été créée");
	} catch (error: any) {
		return error.response;
	}
}
