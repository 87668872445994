import type { SchoolType } from "@/types/schoolTypes";
import { useAppSelector } from "@config/redux/hook";
import { getSchoolBySubdomain } from "@navigation/NavigationAPI";
import { GlobalContext } from "@navigation/Router";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router";

import defaultImage from "@assets/background/ent-default.webp";

import { getCompanySubdomain, logout } from "@tools/Auth";

import { Spinner } from "@designSystem/atoms/Spinner";

import { NoSchool } from "@containers/company/Layout/NoSchool/NoSchool";
import { EmployeeAccessModal } from "@containers/company/ui/EmployeeAccessModal";
import { errorToast } from "@tools/Toasts";
import { NavLink } from "react-router-dom";
import {
	type CompanySliceType,
	setCompanySchoolProfile,
	setEntOffers,
	setSchool,
	setShowEmployeeAccessModal,
	setTitle,
} from "../slice/companySlice";

import type { EntEmployeeStatusType } from "@/types/EntCompanyTypes";
import type { AppState } from "@config/redux/store";
import { LogoutBtn } from "@containers/company/ui/LogoutBtn";
import { getCookie, setCookie } from "@tools/Cookies";
import { Header } from "./Header";
import {
	createProfileApi,
	getProfileApi,
	openInvitationApi,
} from "./LayoutAPI";

export function CompanyLayout() {
	const navigate = useNavigate();
	const [load, setLoad] = useState(true);
	const [showImage, setShowImage] = useState(true);
	const [sideImage, setSideImage] = useState("");
	const [subDomainError, setSubDomainError] = useState(false);

	const location = useLocation();
	const dispatchEvent = useDispatch();
	const queryParameters = new URLSearchParams(window.location.search);

	const { user, setUser } = useContext(GlobalContext);

	const { school, companySchoolProfile } = useAppSelector(
		(state: AppState) => state.company as CompanySliceType,
	);

	const checkMainData = async (schoolSub: string) => {
		dispatchEvent(setShowEmployeeAccessModal(false));
		if (user) {
			if (!user.id.includes("employee")) {
				logout(false, `/?${schoolSub}`);
				return;
			}
			if (!school || !user.entCompany || !companySchoolProfile) {
				await handleMissingProfileData(schoolSub);
			} else {
				handleExistingProfileData();
			}
		} else {
			await handleNoUserCase(schoolSub);
		}
	};

	const handleNoUserCase = async (schoolSub: string) => {
		setLoad(true);
		setSideImage(defaultImage as string);
		const invitationId = queryParameters.get("invitation") || undefined;
		try {
			const schoolData: Partial<SchoolType> =
				await getSchoolBySubdomain(schoolSub);
			const schoolState = {
				id: schoolData.id,
				name: schoolData.name,
				subdomain: schoolSub,
				illustration: schoolData.illustration
					? { filePath: schoolData.illustration }
					: null,
				logo: schoolData.logo ? { filePath: schoolData.logo } : null,
			};
			dispatchEvent(setSchool(schoolState));
			if (invitationId) {
				openInvitationApi(invitationId);
			}
		} catch {
			setSubDomainError(true);
		} finally {
			setLoad(false);
		}
	};

	const handleMissingProfileData = async (schoolSub: string) => {
		if (!user) return;
		setLoad(true);
		try {
			const data = await getProfileApi(schoolSub);
			dispatchEvent(setSchool(data.school));
			setSideImage(
				data.school.illustration?.filePath
					? `${import.meta.env.VITE_S3}${data.school.illustration.filePath}`
					: (defaultImage as string),
			);

			if (!user.entCompany) {
				navigate(`/company/company-create?${schoolSub}&profileNotFound=true`);
				return;
			}

			const profile =
				data.profile ||
				(await createProfileApi(data.school?.id, user.entCompany.id));
			dispatchEvent(setCompanySchoolProfile(profile));
			if (!data.profile) {
				setUser({ ...user, companySchoolsCount: user.companySchoolsCount + 1 });
			}

			if (shouldShowEmployeeAccessModal()) {
				dispatchEvent(setShowEmployeeAccessModal(true));
			}
		} catch {
			setSubDomainError(true);
			errorToast("Une erreur est survenue");
		} finally {
			setLoad(false);
		}
	};

	const handleExistingProfileData = () => {
		if (shouldShowEmployeeAccessModal()) {
			dispatchEvent(setShowEmployeeAccessModal(true));
		}
	};

	const shouldShowEmployeeAccessModal = () => {
		return (
			!!user &&
			user.entEmployeeStatus === "askAccess" &&
			!location.pathname.includes("company-create/sent")
		);
	};

	const getPageTitle = () => {
		if (location.pathname.includes("/company-selections")) {
			dispatchEvent(setTitle("Mes recrutements"));
		} else if (location.pathname.includes("/company-offers/create")) {
			dispatchEvent(setTitle("Votre besoin de recrutement"));
		} else {
			dispatchEvent(
				setTitle({
					title: user ? `Bienvenue ${user.firstname}` : "Bienvenue !",
					subtitle:
						"Découvrez votre espace recruteur et tous les bénéfices de Mentor Goal !",
				}),
			);
		}
	};

	useEffect(() => {
		setShowImage(
			!location.pathname.includes("/company-selections") &&
				!location.pathname.includes("/company-users"),
		);
		getPageTitle();
	}, [location]);

	useEffect(() => {
		if (getCookie("interface") !== "company") {
			setCookie("interface", "company");
		}

		let sub = getCompanySubdomain();
		if (!sub || sub.includes("=")) {
			if (!school || !school.subdomain) {
				dispatchEvent(setEntOffers([]));
				setSubDomainError(true);
				setLoad(false);
				return;
			}
			sub = school.subdomain;
		}

		if (sub?.length) checkMainData(sub);
	}, [location.pathname]);

	return (
		<>
			{load ? (
				<div className="flex h-screen w-full">
					<Spinner className="m-auto" />
				</div>
			) : (
				<>
					<div className="flex h-screen">
						<div className="w-full overflow-y-auto">
							<Header />
							<div className="mx-auto w-11/12">
								{subDomainError ? <NoSchool /> : <Outlet />}
								{user && <LogoutBtn />}
								<p className="mb-lg mt-xsm text-center">
									Propulsé par{" "}
									<NavLink
										to="https://mentorgoal.com/"
										target="_blank"
										className="font-bold"
									>
										Mentor Goal
									</NavLink>
								</p>
							</div>
						</div>
						{showImage ? (
							<div className="hidden h-screen w-6/12 xl:w-8/12 lg:block">
								<img
									className="h-full w-full rounded-l-md"
									src={sideImage}
									alt={school ? school.name : "Espace entreprise"}
								/>
							</div>
						) : null}
					</div>
					{!!user && (
						<EmployeeAccessModal
							status={user.entEmployeeStatus as EntEmployeeStatusType}
						/>
					)}
				</>
			)}
		</>
	);
}
