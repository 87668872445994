import React, { useState } from "react";

import { login } from "@containers/auth/Login/LoginAPI";
import { LoginForm } from "./ui/LoginForm";
export function LoginCompany() {
	const [loading, setLoading] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);

	return (
		<div className="mx-auto mt-sm w-11/12 md:w-10/12 lg:w-8/12">
			<LoginForm
				hasErrors={hasErrors}
				setLoading={setLoading}
				setHasErrors={setHasErrors}
				loading={loading}
				onSubmit={login}
			/>
		</div>
	);
}
