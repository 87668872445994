import type { EntOffersType } from "@/types/EntCompanyTypes";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { arrowBackOutline } from "@assets/Icons";

import { OfferPanel } from "@containers/company/Offers/ui/OfferPanel";
import { OfferSmallList } from "@containers/company/Offers/ui/OfferSmallList";
import { InlineButton } from "@designSystem/atoms/InlineButton";

import type { SchoolType } from "@/types/schoolTypes";
import type { AppState } from "@config/redux/store";
import {
	type CompanySliceType,
	setActiveOffer,
	setEntOffers,
	setEntOffersLoading,
	updateOfferStatus,
} from "@containers/company/slice/companySlice";
import { GlobalContext } from "@navigation/Router";
import { selectionHasNewUsers } from "@tools/Selections";
import { errorToast } from "@tools/Toasts";
import { useDispatch } from "react-redux";
import { closeOfferApi, queryOffers } from "./OffersAPI";
import { CloseOfferModal } from "./ui/CloseOfferModal";
import { OfferCard } from "./ui/OfferCard";
import { OfferSkeleton } from "./ui/OfferSkeleton";

export function Selections() {
	const [offersToTreat, setOffersToTreat] = useState<EntOffersType[] | []>([]);
	const [offersAccepted, setOfferAccepted] = useState<EntOffersType[] | []>([]);
	const [offersRefused, setOffersRefused] = useState<EntOffersType[] | []>([]);
	const [offersPending, setOffersPending] = useState<EntOffersType[] | []>([]);
	const [offersExpired, setOffersExpired] = useState<EntOffersType[] | []>([]);
	const [seeModal, setSeeModal] = useState<false | string>(false);
	const [searchParams] = useSearchParams();

	const {
		school,
		entOffers,
		companySchoolProfile,
		entOffersLoading,
		activeOffer,
	} = useAppSelector((state: AppState) => state.company as CompanySliceType);

	const { user } = useContext(GlobalContext);

	const navigate = useNavigate();
	const dispatchEvent = useDispatch();

	useEffect(() => {
		const getSelections = async () => {
			dispatchEvent(setEntOffersLoading(true));
			try {
				const data: EntOffersType[] = await queryOffers(
					companySchoolProfile?.id as number,
				);
				dispatchEvent(setEntOffers(data));
			} catch {
				errorToast("Une erreur est survenue");
			} finally {
				dispatchEvent(setEntOffersLoading(false));
			}
		};
		getSelections();
	}, [school?.id, user?.entCompany?.id]);

	useEffect(() => {
		if (!entOffers.length) return;

		const offersToTreat = [];
		const offersAccepted = [];
		const offersRefused = [];
		const offersPending = [];
		const offersExpired = [];
		for (const offer of entOffers) {
			if (selectionHasNewUsers(offer.spsSelection)) {
				offersToTreat.push(offer);
			} else if (offer.status === "active") {
				offersAccepted.push(offer);
			} else if (offer.status === "refused") {
				offersRefused.push(offer);
			} else if (offer.status === "pending") {
				offersPending.push(offer);
			} else {
				offersExpired.push(offer);
			}
			setOfferAccepted(offersAccepted);
			setOffersRefused(offersRefused);
			setOffersToTreat(offersToTreat);
			setOffersPending(offersPending);
			setOffersExpired(offersExpired);
		}

		const selectedOffer = searchParams.get("selectedOffer");
		if (selectedOffer) {
			const selectedOfferFound = entOffers.find(
				(o: EntOffersType) => o.id === `/api/ent_offers/${selectedOffer}`,
			);
			if (selectedOfferFound) dispatchEvent(setActiveOffer(selectedOfferFound));
		}
	}, [entOffers, searchParams.get]);

	const renderCategory = (entOffers: EntOffersType[], label: string) => {
		if (!entOffers.length) return;
		return (
			<div className="mb-sm">
				<p className="text-sm	font-bold -mb-sm">{label}</p>
				{entOffers.map((offer, index) => (
					<OfferCard
						key={offer.id}
						school={school as Partial<SchoolType>}
						index={index}
						seeModal={seeModal}
						setSeeModal={setSeeModal}
						offer={offer}
						onSelect={() => {
							dispatchEvent(setActiveOffer(offer));
						}}
					/>
				))}
			</div>
		);
	};

	return (
		<div>
			<InlineButton
				className="my-sm"
				label="Retour"
				icon={arrowBackOutline}
				onClick={() => {
					navigate(`/?${school?.subdomain || ""}`);
				}}
			/>
			<h1 className="!text-xlg">Mes recrutements</h1>
			{!activeOffer ? (
				<div>
					{entOffersLoading ? (
						<div className="flex flex-col gap-md mt-lg">
							<OfferSkeleton />
							<OfferSkeleton />
						</div>
					) : entOffers.length === 0 ? (
						<div className="my-lg w-full">
							<p className="mb-md text-center text-xlg font-bold">0</p>
							<p className="text-center font-bold">
								Aucun recrutement à ce jour
							</p>
						</div>
					) : (
						<div className="mt-sm">
							{renderCategory(offersToTreat, "À traiter")}
							{renderCategory(offersAccepted, "Acceptées")}
							{renderCategory(offersRefused, "Refusées")}
							{renderCategory(offersPending, "En attente")}
							{renderCategory(offersExpired, "Terminées")}
						</div>
					)}
				</div>
			) : (
				<>
					<div className="mt-md gap-sm lg:flex">
						<div className="h-fit-content hidden w-full rounded-md bg-white slg:w-4/12 lg:flex xl:w-3/12 ">
							<OfferSmallList
								onGoBack={() => {
									dispatchEvent(setActiveOffer(undefined));
								}}
								offersAccepted={offersAccepted}
								offersToTreat={offersToTreat}
								offersRefused={offersRefused}
								offersPending={offersPending}
								offersExpired={offersExpired}
							/>
						</div>
						<button
							type="button"
							className="mb-sm flex items-center rounded-md bg-white px-xsm py-xxsm shadow-sm lg:hidden"
							onClick={() => dispatchEvent(setActiveOffer(undefined))}
						>
							<Icon
								icon={arrowBackOutline}
								className="inline rounded border-b-2 border-l border-r-2 border-t border-primary-900"
							/>
							<p className="ml-1 font-bold">Retourner à la liste</p>
						</button>
						<OfferPanel
							isCompany={true}
							seeModal={seeModal}
							setSeeModal={setSeeModal}
						/>
					</div>
				</>
			)}
			<CloseOfferModal
				onClose={() => {
					setSeeModal(false);
				}}
				show={seeModal !== false}
				onDelete={async () => {
					const id = seeModal as string;
					setSeeModal(false);
					dispatchEvent(updateOfferStatus({ offer: id, newStatus: "expired" }));
					closeOfferApi(id);
				}}
			/>
		</div>
	);
}
