import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { arrowBackOutline } from "@assets/Icons";

import { InlineButton } from "@designSystem/atoms/InlineButton";

import { useAppSelector } from "@config/redux/hook";
import type { AppState } from "@config/redux/store";
import type { CompanySliceType } from "@containers/company/slice/companySlice";
import { GlobalContext } from "@navigation/Router";
import { CompanyCreated } from "./ui/CompanyCreated";
import { CompanyForm } from "./ui/CompanyForm";
import { ProfileCreated } from "./ui/ProfileCreated";
import { ProfileForm } from "./ui/ProfileForm";
import { ValidateEmail } from "./ui/ValidateEmail";

export function RegisterCompany() {
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [hasErrors, setHasErrors] = useState(false);
	const [step, setStep] = useState(1);
	const [siret, setSiret] = useState("");
	const [email, setEmail] = useState("");

	const { school } = useAppSelector(
		(state: AppState) => state.company as CompanySliceType,
	);

	const { user } = useContext(GlobalContext);
	const location = useLocation();
	const navigate = useNavigate();
	const goNext = (add = 1) => {
		setStep(step + add);
	};
	useEffect(() => {
		if (!showModal && siret.length) {
			goNext();
		}
	}, [showModal]);
	const goBack = () => {
		setStep(step - 1);
	};
	useEffect(() => {
		if (location.pathname.includes("/company-create")) {
			if (user?.entCompany) {
				navigate(`/?${school?.subdomain || ""}`);
			}
			setStep(3);
		}
	}, []);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [step]);
	return (
		<div className="mx-auto mt-sm w-11/12 md:w-10/12 lg:w-8/12">
			<InlineButton
				className="mb-sm"
				label="Retour"
				icon={arrowBackOutline}
				onClick={() => {
					switch (step) {
						case 1:
						case 3:
							navigate(`/?${school?.subdomain || ""}`);
							break;
						default:
							goBack();
					}
				}}
			/>
			{step === 1 ? (
				<ProfileForm
					hasErrors={hasErrors}
					setLoading={setLoading}
					setEmail={setEmail}
					setHasErrors={setHasErrors}
					loading={loading}
					goNext={goNext}
				/>
			) : null}
			{step === 2 ? (
				<ValidateEmail
					email={email}
					loading={loading}
					setLoading={setLoading}
				/>
			) : null}
			{step === 3 ? (
				<ProfileCreated
					showModal={showModal}
					setShowModal={setShowModal}
					loading={loading}
					goNext={() => {
						setShowModal(false);
					}}
					setSiret={setSiret}
					siret={siret}
					setLoading={setLoading}
				/>
			) : null}
			{step === 4 ? (
				<CompanyForm
					setHasErrors={setHasErrors}
					loading={loading}
					goNext={goNext}
					siret={siret}
				/>
			) : null}
			{step === 5 ? <CompanyCreated /> : null}
		</div>
	);
}
