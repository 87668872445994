import type { ApplicationType } from "@/types/applicationTypes";
import type { ScrapedOfferType } from "@/types/offerTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
	alertTriangleOutline,
	checkmarkOutline,
	closeOutline,
	externalLinkOutline,
} from "@assets/Icons";

import { extractIdNumberFromId } from "@tools/Users";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { Divider } from "@designSystem/atoms/Divider";
import { Spinner } from "@designSystem/atoms/Spinner";
import { TooltipContainer } from "@designSystem/atoms/TooltipContainer";
import { CardTertiary } from "@designSystem/molecules/CardTertiary";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { errorToast, success } from "@tools/Toasts";

import { InlineButton } from "@designSystem/atoms/InlineButton";
import { getCookie } from "@tools/Cookies";
import {
	editApplication,
	importOffer,
	signalThisOffer,
} from "../../core/api/jobboard.api";
import { setUserApplications } from "../../core/store/betaJobboard.slice";

export function HaveYouPostulateModal({
	offer,
	show,
	onClose,
	setApplication,
	postulateUsingMail,
	mailForApplication,
	isSchoolOffer = false,
}: {
	offer: ScrapedOfferType;
	show: boolean;
	onClose: Function;
	setApplication?: Function;
	postulateUsingMail?: boolean;
	mailForApplication?: string;
	isSchoolOffer?: boolean;
}) {
	const dispatchJobboard = useDispatch();
	const { userApplications } = useAppSelector((state) => state.betaJobboard);
	const [load, setLoad] = useState(false);

	type updateApplicationResponse = {
		application?: ApplicationType;
	};

	const updateApplication = async (applicationId: string) => {
		const { application }: updateApplicationResponse = await editApplication({
			id: applicationId,
			step: "sent",
		});

		if (setApplication) setApplication(application);
		success("Candidature envoyée");
	};

	const isSchoolOfer = () => {
		return "school" in offer;
	};

	const createApplication = async () => {
		try {
			const { applicationId }: any = await importOffer(
				typeof offer?.id === "number" ? offer.id : offer.id.split("/")[3],
				!isSchoolOfer(),
			);
			success("Candidature enregistrée");
			if (userApplications && setUserApplications) {
				dispatchJobboard(
					setUserApplications([
						...userApplications,
						{ offer: { id: offer.id } },
					]),
				);
			}
			if (setApplication) {
				setApplication({
					id: `/${getCookie("interface")}/applications/${applicationId}`,
				});
			}
		} catch (_e) {
			errorToast("Une erreur est survenue");
		}
	};

	const candidate = async () => {
		setLoad(true);
		const application =
			offer?.applications?.collection?.filter(
				(application: ApplicationType) => {
					return application.step === "readyToApply";
				},
			)[0] ?? undefined;

		if (application) {
			await updateApplication(application.id);
		} else {
			await createApplication();
		}

		setLoad(false);

		onClose();
	};

	const getSignalButton = () => {
		if (!offer || isSchoolOffer) return null;
		return (
			<InlineButton
				icon={alertTriangleOutline}
				iconPosition="left"
				onClick={() => {
					signalThisOffer(offer.id);
				}}
				label="Signaler l'offre"
			/>
		);
	};
	return (
		<Modal
			show={show}
			onClose={() => {
				onClose();
			}}
			title={postulateUsingMail ? "Postuler" : "Tu as postulé ?"}
			buttonsLeft={[getSignalButton()]}
			body={
				!load ? (
					<div>
						{postulateUsingMail ? (
							<div className="flex flex-col gap-sm">
								<p>
									Pour candidater à cette offre, envoie ta candidature par mail
									à :{" "}
									<span className="text-primary-700P font-bold">
										{mailForApplication}
									</span>
								</p>
								<ButtonPrimary
									label="Ouvrir ma boîte mail"
									onClick={() => {
										window.location.href = `mailto:${mailForApplication}`;
									}}
									icon={externalLinkOutline}
									iconPlacement="left"
								/>
								<Divider />
							</div>
						) : (
							<p>
								Tu viens de consulter une offre. As-tu candidaté à cette offre ?
							</p>
						)}
						<div className="mt-sm flex flex-col gap-md md:flex-row">
							<span
								className="w-full md:w-6/12"
								id={`application-sent-${extractIdNumberFromId(offer.id)}`}
							>
								<CardTertiary
									icon={checkmarkOutline}
									iconColor="text-success-darker"
									className="px-xxsm py-sm"
									title="Oui, j’ai envoyé ma candidature"
									onSelect={() => {
										candidate();
									}}
									disabled={false}
								/>
								<TooltipContainer
									anchorId={`#application-sent-${extractIdNumberFromId(
										offer.id,
									)}`}
									children="Je la retrouve dans mon tableau de suivi “Mes candidatures”, colonne “Candidature envoyée”."
									place="top"
								/>
							</span>
							<span
								className="w-full md:w-6/12"
								id={`application-aborted-${extractIdNumberFromId(offer.id)}`}
							>
								<CardTertiary
									icon={closeOutline}
									iconColor="text-alert-error-500"
									className="px-xxsm py-sm"
									title="Non, je ne suis pas intéressé"
									onSelect={() => {
										onClose();
									}}
									disabled={false}
								/>
								<TooltipContainer
									anchorId={`#application-aborted-${extractIdNumberFromId(
										offer.id,
									)}`}
									children="L’offre sera ignorée. Tu pourras toujours la retrouver ici."
									place="top"
								/>
							</span>
						</div>
					</div>
				) : (
					<div className="flex h-full w-full items-center justify-center">
						<Spinner />
					</div>
				)
			}
			buttonsRight={[]}
		/>
	);
}
