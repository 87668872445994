import { Spinner } from "@/designSystem/atoms/Spinner";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function ErrorNotFound() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(`/?redirectTo=${window.location.pathname}`);
	}, []);

	return (
		<main className="flex h-screen w-screen justify-center items-center">
			<Spinner />
		</main>
	);
}
