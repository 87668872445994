import type { ApplicationType } from "@/types/applicationTypes";
import type { SchoolOfferType, ScrapedOfferType } from "@/types/offerTypes";
import type { UserType } from "@/types/userTypes";
import { createSlice } from "@reduxjs/toolkit";

import type { SearchHistoryType } from "@containers/student/BetaJobboard/core/types/jobboard.types";
import { getCookie } from "@tools/Cookies";
import { errorToast, success } from "@tools/Toasts";
import {
	createHistorySearch,
	deleteSearchHistoryItem,
	fetchSchoolOffers,
	fetchSchoolOffersCount,
	fetchScrapedOffers,
	getStudentHistory,
} from "./betaJobboard.thunks";

export type JobboardFiltersType = {
	tab?: string | "schoolOffers" | "jobboardsOffers" | "jobboardAndExtension";
	page?: number;
	search?: string;
	position?: [number, number];
	location?: string;
	max_distance?: number;
	contract?: string;
	jobboard_list?: Array<string>;
	exists?: {
		school: boolean;
	};
	school?: string | null;
};
export type JobboardSliceState = {
	tab: string;
	schoolOffers: Array<SchoolOfferType>;
	scrapedOffers: Array<ScrapedOfferType>;
	lastPage: number;
	loadSchoolOffers: boolean;
	loadScrapedOffers: boolean;
	totalSchoolCount: number;
	totalScrapedCount: number;
	user: UserType | undefined;
	filters: JobboardFiltersType;
	userApplications: Array<ApplicationType>;
	jobboardWasInitialized: boolean;
	loadSearchHistory: boolean;
	selectedSearchHistoryItem: SearchHistoryType | null;
	searchHistory: Array<SearchHistoryType>;
	showSearchesModal: boolean;
	showCreateSearchModal: boolean;
};
const getSettingsCookie = (name: string, defaultValue: any) => {
	const cookie = getCookie(name);
	if (cookie) {
		return { ...JSON.parse(getCookie(name) as string) };
	}
	return defaultValue;
};
const getTabCookie = () => {
	const tabCookie = getSettingsCookie("JobboardFilters", "schoolOffers");
	return typeof tabCookie === "string" ? tabCookie : tabCookie?.tab;
};

const initialState: JobboardSliceState = {
	loadSchoolOffers: false,
	loadScrapedOffers: false,
	tab: getTabCookie(),
	schoolOffers: [],
	scrapedOffers: [],
	lastPage: 1,
	totalSchoolCount: 0,
	totalScrapedCount: 0,
	user: undefined,
	filters: getSettingsCookie("JobboardFilters", {
		page: 1,
	}),
	userApplications: [],
	jobboardWasInitialized: false,
	selectedSearchHistoryItem: null,
	searchHistory: [],
	loadSearchHistory: false,
	showSearchesModal: true,
	showCreateSearchModal: false,
};

const betaJobboardSlice = createSlice({
	name: "BetaJobboardSlice",
	initialState,
	reducers: {
		setTab(state, action) {
			state.tab = action.payload;
		},
		addUserApplication(state, action) {
			const applications = [...state.userApplications];
			applications.push(action.payload);
			state.userApplications = applications;
		},
		setUserApplications(state, action) {
			state.userApplications = action.payload;
		},
		setFilters(state, action) {
			state.filters = action.payload;
		},
		setTotalScrapedOffersCount(state, action) {
			state.totalScrapedCount = action.payload;
		},
		setSelectedSearchHistoryItem(state, action) {
			state.selectedSearchHistoryItem = action.payload;
			state.filters = {
				tab: "schoolOffers",
				max_distance: action.payload.distance,
				location: action.payload.city,
				contract: action.payload.contract,
				position: [action.payload.longitude, action.payload.latitude],
				search: action.payload.search,
				page: 1,
			};
			state.showSearchesModal = false;
		},
		setSearchHistory(state, action) {
			state.searchHistory = action.payload;
		},
		setShowSearchesModal(state, action) {
			state.showSearchesModal = action.payload;
		},
		setShowCreateSearchModal(state, action) {
			state.showCreateSearchModal = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(fetchSchoolOffers.pending, (state: JobboardSliceState) => {
			state.schoolOffers = [];
			state.lastPage = 1;
			state.totalSchoolCount = 0;
			state.loadSchoolOffers = true;
		});
		builder.addCase(
			fetchSchoolOffers.fulfilled,
			(state: JobboardSliceState, action) => {
				state.schoolOffers =
					action.payload.data.collectionQueryOffers.collection;
				state.lastPage =
					action.payload.data.collectionQueryOffers.paginationInfo.lastPage;
				state.totalSchoolCount =
					action.payload.data.collectionQueryOffers.paginationInfo.totalCount;

				if (!state.jobboardWasInitialized) {
					state.jobboardWasInitialized = true;
				}
				state.loadSchoolOffers = false;
			},
		);
		builder.addCase(
			fetchSchoolOffers.rejected,
			(state: JobboardSliceState, action) => {
				if (action.error.message !== "aborted") {
					errorToast(
						"Une erreur est survenue lors de la récupération des données.",
					);
					state.loadSchoolOffers = false;
				}
			},
		);
		builder.addCase(
			fetchSchoolOffersCount.pending,
			(state: JobboardSliceState) => {
				state.loadSchoolOffers = true;
			},
		);
		builder.addCase(
			fetchSchoolOffersCount.fulfilled,
			(state: JobboardSliceState, action) => {
				state.totalSchoolCount =
					action.payload.data.collectionQueryOffers.paginationInfo.totalCount;
				state.loadSchoolOffers = false;
			},
		);
		builder.addCase(
			fetchSchoolOffersCount.rejected,
			(state: JobboardSliceState, action) => {
				if (action.error.message !== "aborted") {
					errorToast(
						"Une erreur est survenue lors de la récupération des données.",
					);
					state.loadSchoolOffers = false;
				}
			},
		);
		builder.addCase(fetchScrapedOffers.pending, (state: JobboardSliceState) => {
			state.loadScrapedOffers = true;
		});
		builder.addCase(
			fetchScrapedOffers.fulfilled,
			(state: JobboardSliceState, action) => {
				state.scrapedOffers =
					action.payload.data.collectionQueryOffers.collection;
				state.lastPage =
					action.payload.data.collectionQueryOffers.paginationInfo.lastPage;
				state.totalScrapedCount =
					action.payload.data.collectionQueryOffers.paginationInfo.totalCount;
				if (!state.jobboardWasInitialized) {
					state.jobboardWasInitialized = true;
				}
				state.loadScrapedOffers = false;
			},
		);
		builder.addCase(
			fetchScrapedOffers.rejected,
			(state: JobboardSliceState, action) => {
				if (action.error.message !== "aborted") {
					errorToast(
						"Une erreur est survenue lors de la récupération des données.",
					);
					state.loadScrapedOffers = false;
				}
			},
		);
		builder.addCase(getStudentHistory.pending, (state: JobboardSliceState) => {
			state.loadSearchHistory = true;
		});
		builder.addCase(
			getStudentHistory.fulfilled,
			(state: JobboardSliceState, action) => {
				state.searchHistory =
					action.payload.data.spsStudent.spsOfferSearch.collection;
				state.loadSearchHistory = false;
			},
		);
		builder.addCase(
			getStudentHistory.rejected,
			(state: JobboardSliceState, action) => {
				if (action.error.message !== "aborted") {
					errorToast(
						"Une erreur est survenue lors de la récupération de vos recherches.",
					);
					state.loadSearchHistory = false;
				}
			},
		);
		builder.addCase(
			deleteSearchHistoryItem.pending,
			(state: JobboardSliceState) => {
				state.loadSearchHistory = true;
			},
		);
		builder.addCase(
			deleteSearchHistoryItem.fulfilled,
			(state: JobboardSliceState, action) => {
				state.searchHistory = state.searchHistory.filter(
					(searchHistoryItem) =>
						searchHistoryItem.id !==
						action.payload.data.deleteSpsOfferSearch.spsOfferSearch.id,
				);
				if (
					state.selectedSearchHistoryItem?.id ===
					action.payload.data.deleteSpsOfferSearch.spsOfferSearch.id
				) {
					state.selectedSearchHistoryItem = null;
				}
				state.loadSearchHistory = false;
				success("La recherche a bien été supprimée");
			},
		);
		builder.addCase(
			deleteSearchHistoryItem.rejected,
			(state: JobboardSliceState, action) => {
				if (action.error.message !== "aborted") {
					errorToast(
						"Une erreur est survenue lors de la suppression de la recherche.",
					);
					state.loadSearchHistory = false;
				}
			},
		);
		builder.addCase(
			createHistorySearch.pending,
			(state: JobboardSliceState) => {
				state.loadSearchHistory = true;
			},
		);
		builder.addCase(
			createHistorySearch.fulfilled,
			(state: JobboardSliceState, action) => {
				state.searchHistory = [
					...state.searchHistory,
					action.payload.data.createSpsOfferSearch.spsOfferSearch,
				];
				state.loadSearchHistory = false;
				success("La recherche a bien été créée");
				state.showSearchesModal = true;
				state.showCreateSearchModal = false;
			},
		);
		builder.addCase(
			createHistorySearch.rejected,
			(state: JobboardSliceState, action) => {
				if (action.error.message !== "aborted") {
					errorToast(
						"Une erreur est survenue lors de la création de la recherche.",
					);
					state.loadSearchHistory = false;
				}
			},
		);
	},
});

export const {
	setTab,
	setUserApplications,
	addUserApplication,
	setFilters,
	setTotalScrapedOffersCount,
	setSelectedSearchHistoryItem,
	setSearchHistory,
	setShowSearchesModal,
	setShowCreateSearchModal,
} = betaJobboardSlice.actions;

export default betaJobboardSlice.reducer;
