import React from "react";
import { useNavigate } from "react-router";

import { useAppSelector } from "@config/redux/hook";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { WhiteCard } from "@designSystem/molecules/WhiteCard";

export function CompanyCreated() {
	const { school } = useAppSelector((state) => state.company);
	const navigate = useNavigate();
	return (
		<div className="bg-red mx-auto mb-lg w-full">
			<WhiteCard
				className="mb-lg"
				title="Félicitations !"
				content={
					<div className="flex flex-col gap-sm w-full">
						<p className="text-center">Votre entreprise a bien été créée.</p>
						<p className="text-center mb-md">
							Vous pouvez désormais accéder à l'espace entreprise.
						</p>
						<ButtonPrimary
							className="mx-auto"
							label="Espace entreprise"
							onClick={() => {
								navigate(`/?${school?.subdomain || ""}`);
							}}
						/>
					</div>
				}
			/>
		</div>
	);
}
