import type { LogType } from "@/types/logTypes";

import {
	alertCircleOutline,
	briefcaseOutline,
	clockOutline,
	doneAllOutline,
	downloadOutline,
	editOutline,
	fileTextOutline,
	logInOutline,
	paperPlaneOutline,
	plusOutline,
	trash2Outline,
} from "@assets/Icons";

export const LOG_ACTION_OPTIONS = [
	{ label: "Connexion", value: "connection", type: "checkbox", checked: false },
	{
		label: "Première connexion",
		value: "firstConnection",
		type: "checkbox",
		checked: false,
	},
	{
		label: "Contact créé",
		value: "contactCreated",
		type: "checkbox",
		checked: false,
	},
	{
		label: "Création de candidature",
		value: "creatingAnApplication",
		type: "checkbox",
		checked: false,
	},
	{
		label: "Candidature envoyée",
		value: "applicationSent",
		type: "checkbox",
		checked: false,
	},
	{ label: "Relance", value: "relaunch", type: "checkbox", checked: false },
	{
		label: "Entretien obtenu",
		value: "interviewObtained",
		type: "checkbox",
		checked: false,
	},
	{
		label: "Modification de CV",
		value: "modifyCV",
		type: "checkbox",
		checked: false,
	},
	{
		label: "Dépot de CV",
		value: "submitCV",
		type: "checkbox",
		checked: false,
	},
	{
		label: "Suppression de CV",
		value: "deleteCV",
		type: "checkbox",
		checked: false,
	},
	{
		value: "cvValidated",
		label: "Validation de CV",
		type: "checkbox",
		checked: false,
	},
	{
		value: "cvToModify",
		label: "Demande de modification de CV",
		type: "checkbox",
		checked: false,
	},
	{
		value: "cvReminder",
		label: "Relance de dépot de CV",
		type: "checkbox",
		checked: false,
	},
	{
		value: "loginReminder",
		label: "Relance de connection",
		type: "checkbox",
		checked: false,
	},
	{
		value: "shareJobOffer",
		label: "Partage d'offre",
		type: "checkbox",
		checked: false,
	},
	{
		value: "createStudent",
		label: "Création de profil",
		type: "checkbox",
		checked: false,
	},
	{
		value: "updateProfile",
		label: "Modification de profil",
		type: "checkbox",
		checked: false,
	},
	{
		value: "changeStatus",
		label: "Changement de statut",
		type: "checkbox",
		checked: false,
	},
	{
		value: "completeProfile",
		label: "Complétion de profil",
		type: "checkbox",
		checked: false,
	},
	{
		value: "updateProfile",
		label: "Modification profil",
		type: "checkbox",
		checked: false,
	},
	{
		value: "submitMotivationLetter",
		label: "Dépot de lettre de motivation",
		type: "checkbox",
		checked: false,
	},
	{
		value: "deleteMotivationLetter",
		label: "Suppression de lettre de motivation",
		type: "checkbox",
		checked: false,
	},
	{
		value: "responseToEvent",
		label: "Réponse à un évènement",
		type: "checkbox",
		checked: false,
	},
	{
		value: "createSpontaneousApplication",
		label: "Candidature spontanée",
		type: "checkbox",
		checked: false,
	},
	{
		value: "selectionSharedByMail",
		label: "Partage de sélection par mail",
		type: "checkbox",
		checked: false,
	},
	{
		value: "createOffer",
		label: "Création d'offre",
		type: "checkbox",
		checked: false,
	},
	{
		value: "updateOffer",
		label: "Modification d'offre",
		type: "checkbox",
		checked: false,
	},
	{
		value: "createEvent",
		label: "Création d'évènement",
		type: "checkbox",
		checked: false,
	},
	{
		value: "updateEvent",
		label: "Modification d'évènement",
		type: "checkbox",
		checked: false,
	},
	{
		value: "createResource",
		label: "Création de ressource",
		type: "checkbox",
		checked: false,
	},
	{
		value: "updateResource",
		label: "Modification de ressource",
		type: "checkbox",
		checked: false,
	},
	{
		value: "createPromotion",
		label: "Création de promotion",
		type: "checkbox",
		checked: false,
	},
	{
		value: "updatePromotion",
		label: "Modification de promotion",
		type: "checkbox",
		checked: false,
	},
];

export const LOGS_LABELS: LogType[] = [
	{
		action: "interviewObtained",
		firstPersonLabel: "Tu as <b>obtenu un entretien</b> &#128079;",
		thirdPersonLabel: "a <b>obtenu un entretien</b> &#128079;",
		icon: briefcaseOutline,
	},
	{
		action: "relaunch",
		firstPersonLabel: "Tu as <b>relancé une candidature</b> &#128170;",
		thirdPersonLabel: "a <b>relancé une candidature</b> &#128170;",
		icon: briefcaseOutline,
	},
	{
		action: "connection",
		firstPersonLabel: "Tu t'es connecté",
		thirdPersonLabel: "s'est connecté",
		icon: logInOutline,
	},
	{
		action: "updateProfile",
		firstPersonLabel: "Tu as modifié ton profil",
		thirdPersonLabel: "a modifié son profil",
		icon: editOutline,
	},
	{
		action: "firstConnection",
		firstPersonLabel: "Tu t'es connecté pour la première fois",
		thirdPersonLabel: "s'est connecté pour la première fois",
		icon: logInOutline,
	},
	{
		action: "creatingAnApplication",
		firstPersonLabel: "Tu as <b>créé une candidature</b>",
		thirdPersonLabel: "a <b>créé une candidature</b>",
		icon: plusOutline,
	},
	{
		action: "applicationSent",
		firstPersonLabel: "Tu as <b>envoyé une candidature</b> &#128640;",
		thirdPersonLabel: "a <b>envoyé à une candidature</b> &#128640;",
		icon: fileTextOutline,
	},
	{
		action: "contactCreated",
		firstPersonLabel: "Tu as créé un contact",
		thirdPersonLabel: "a créé un contact",
		icon: fileTextOutline,
	},
	{
		action: "consultContact",
		firstPersonLabel: "Tu as modifié un contact",
		thirdPersonLabel: "a modifié un contact",
		icon: editOutline,
	},
	{
		action: "selectionViewed",
		firstPersonLabel: "Ta sélection a été consultée",
		thirdPersonLabel: ": votre sélection a été constulée",
		icon: fileTextOutline,
	},
	{
		action: "invitationSendBySMS",
		firstPersonLabel: "Tu as été invité par SMS",
		thirdPersonLabel: "a été invité par SMS",
		icon: fileTextOutline,
	},
	{
		action: "offerSharedBySMS",
		firstPersonLabel: "Tu as reçu une offre par SMS",
		thirdPersonLabel: "a reçu une offre par SMS",
		icon: fileTextOutline,
	},
	{
		action: "planAction",
		firstPersonLabel: "Tu as planifié une action",
		thirdPersonLabel: "a planifié une action",
		icon: fileTextOutline,
	},
	{
		action: "completeAction",
		firstPersonLabel: "Tu as complété une action",
		thirdPersonLabel: "a complété une action",
		icon: fileTextOutline,
	},
	{
		action: "applicationAccepted",
		firstPersonLabel: "Ta as accepté une candidature",
		thirdPersonLabel: "a accepté une candidature",
		icon: fileTextOutline,
	},
	{
		action: "applicationRefused",
		firstPersonLabel: "Tu as refusé une candidature",
		thirdPersonLabel: "a refusé une candidature",
		icon: fileTextOutline,
	},
	{
		action: "createPromotion",
		firstPersonLabel: "Tu as créé une promotion",
		thirdPersonLabel: "a créé une promotion",
		icon: fileTextOutline,
	},
	{
		action: "archivedPromotion",
		firstPersonLabel: "Tu as archivé une promotion",
		thirdPersonLabel: "a archivé une promotion",
		icon: fileTextOutline,
	},
	{
		action: "consultResource",
		firstPersonLabel: "Tu as consulté une ressource",
		thirdPersonLabel: "a consulté une ressource",
		icon: fileTextOutline,
	},
	{
		firstPersonLabel: "Tu as modifié ton CV",
		thirdPersonLabel: "a modifié son CV",
		action: "modifyCV",
		icon: editOutline,
	},
	{
		firstPersonLabel: "Tu as déposé ton CV",
		thirdPersonLabel: "a déposé son CV",
		action: "submitCV",
		icon: downloadOutline,
	},
	{
		firstPersonLabel: "Tu as supprimé ton CV",
		thirdPersonLabel: "a supprimé son CV",
		action: "deleteCV",
		icon: trash2Outline,
	},
	{
		action: "cvValidated",
		icon: doneAllOutline,
		firstPersonLabel: "Ton CV a été validé",
		thirdPersonLabel: "a reçu une validation pour son CV",
	},
	{
		action: "cvToModify",
		icon: alertCircleOutline,
		firstPersonLabel: "Ton CV a été déclaré comme à modifier",
		thirdPersonLabel: "a reçu une demande de modification de CV",
	},
	{
		action: "cvReminder",
		icon: clockOutline,
		firstPersonLabel: "Tu as reçu une relance de dépot de CV",
		thirdPersonLabel: "a reçu une relance de dépot de CV",
	},
	{
		action: "loginReminder",
		icon: clockOutline,
		firstPersonLabel: "Tu as reçu une relance de connection",
		thirdPersonLabel: "a envoyé une relance de connection",
	},
	{
		action: "shareJobOffer",
		icon: paperPlaneOutline,
		firstPersonLabel: "Une offre a été partagée avec toi",
		thirdPersonLabel: "a partagé une offre avec l'étudiant",
	},
	{
		action: "createStudent",
		icon: plusOutline,
		firstPersonLabel: "Ton profil a été créé",
		thirdPersonLabel: "a créé le profil de l'étudiant",
	},
	{
		action: "updateStudent",
		icon: editOutline,
		firstPersonLabel: "Ton profil a été modifié",
		thirdPersonLabel: "a modifié le profil de l'étudiant",
	},
	{
		action: "changeStatus",
		icon: editOutline,
		firstPersonLabel: "Ton statut a été modifié",
		thirdPersonLabel: "a changé le statut de l'étudiant",
	},
	{
		action: "schoolRefusesStudentStatus",
		icon: editOutline,
		firstPersonLabel: "Ton école a refusé ton changement de statut",
		thirdPersonLabel: ": changement de statut refusé par l'école",
	},
	{
		action: "schoolAcceptsStudentStatus",
		icon: editOutline,
		firstPersonLabel: "Ton école a accepté ton changement de statut",
		thirdPersonLabel: ": changement de statut accepté par l'école",
	},
	{
		action: "studentUpdatesStudentStatus",
		icon: editOutline,
		firstPersonLabel: "Tu as demandé un changement de statut",
		thirdPersonLabel: "a demandé un changement de statut",
	},
	{
		action: "schoolUpdatesStudentStatus",
		icon: editOutline,
		firstPersonLabel: "Ton école a modifié ton statut",
		thirdPersonLabel: ": statut modifié par l'école",
	},
	{
		action: "completeProfile",
		icon: doneAllOutline,
		firstPersonLabel: "Tu as complété ton profil",
		thirdPersonLabel: "a complété son profil",
	},
	{
		action: "modifyProfile",
		icon: editOutline,
		firstPersonLabel: "Tu as modifié ton profil",
		thirdPersonLabel: "a modifié son profil",
	},
	{
		action: "submitMotivationLetter",
		icon: downloadOutline,
		firstPersonLabel: "Tu as déposé ta lettre de motivation",
		thirdPersonLabel: "A déposé sa lettre de motivation",
	},
	{
		action: "deleteMotivationLetter",
		icon: trash2Outline,
		firstPersonLabel: "Tu as supprimé ta lettre de motivation",
		thirdPersonLabel: "a supprimé sa lettre de motivation",
	},
	{
		action: "responseToEvent",
		icon: paperPlaneOutline,
		firstPersonLabel: "Tu as indiqué une réponse à un évènement",
		thirdPersonLabel: "a indiqué une réponse à un évènement",
	},
	{
		action: "createSpontaneousApplication",
		icon: editOutline,
		firstPersonLabel: "Tu as créé une candidature spontanée",
		thirdPersonLabel: "a créé une candidature spontanée",
	},
	{
		action: "applyToPartnerOffer",
		icon: briefcaseOutline,
		firstPersonLabel: "Tu as postulé à une offre partenaire",
		thirdPersonLabel: "a postulé à une offre partenaire",
	},
	{
		action: "applyToExternalOffer",
		icon: briefcaseOutline,
		firstPersonLabel: "Tu as postulé à une offre externe",
		thirdPersonLabel: "a postulé à une offre externe",
	},
	{
		action: "exportStudent",
		icon: editOutline,
		firstPersonLabel: "Tu as exporté un ou plusieurs étudiant(s)",
		thirdPersonLabel: "a exporté un ou plusieurs étudiant(s)",
	},
	{
		action: "consultOffer",
		icon: editOutline,
		firstPersonLabel: "Tu as consulté une offre",
		thirdPersonLabel: "a consulté une offre",
	},
	{
		action: "selectionSharedByMail",
		icon: editOutline,
		firstPersonLabel: "Tu as partagé une sélection de profils par mail",
		thirdPersonLabel: "a partagé une sélection de profils par mail",
	},
	{
		action: "createOffer",
		icon: editOutline,
		firstPersonLabel: "Tu as créé une offre",
		thirdPersonLabel: "a créé une offre",
	},
	{
		action: "updateOffer",
		icon: editOutline,
		firstPersonLabel: "Tu as modifié une offre",
		thirdPersonLabel: "a modifié une offre",
	},
	{
		action: "createEvent",
		icon: editOutline,
		firstPersonLabel: "Tu as créé un évènement",
		thirdPersonLabel: "a créé un évènement",
	},
	{
		action: "updateEvent",
		icon: editOutline,
		firstPersonLabel: "Tu as modifié un évènement",
		thirdPersonLabel: "a modifié un évènement",
	},
	{
		action: "createResource",
		icon: editOutline,
		firstPersonLabel: "Tu as créé une ressource",
		thirdPersonLabel: "a créé une ressource",
	},
	{
		action: "updateResource",
		icon: editOutline,
		firstPersonLabel: "Tu as modifié une ressource",
		thirdPersonLabel: "a modifié une ressource",
	},
	{
		action: "createPromotion",
		icon: editOutline,
		firstPersonLabel: "Tu as créé une promotion",
		thirdPersonLabel: "a créé une promotion",
	},
	{
		action: "updatePromotion",
		icon: editOutline,
		firstPersonLabel: "Tu as modifié une promotion",
		thirdPersonLabel: "a modifié une promotion",
	},
];
