import type { ApplicationThumbType } from "@/types/applicationTypes";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import React from "react";

import { chevronRightOutline, doneAllOutline, pinOutline } from "@assets/Icons";

import { Avatar } from "@designSystem/atoms/Avatar";
import { Badge } from "@designSystem/atoms/Badge";

import { setSelectedApplicationId } from "@containers/school/Offers_v3/core/store/offersManagement.slice";

export function SentThumb({
	dispatchEvent,
	thumbnailData,
	isSelected = false,
	isSent = false,
}: {
	dispatchEvent: any;
	thumbnailData: ApplicationThumbType;
	isSelected?: boolean;
	isSent: boolean;
}) {
	const { offerToEdit } = useAppSelector((state) => state.offers);
	const handleSelectThisApplication = () => {
		dispatchEvent(setSelectedApplicationId(thumbnailData?.id));
	};
	return (
		<div
			className={`flex w-full justify-between rounded-md border ${
				isSelected ? "border-primary-500" : "border-transparent"
			} px-sm py-xsm shadow-md transition-all hover:cursor-pointer hover:border-primary-500`}
			onClick={() => handleSelectThisApplication()}
		>
			<div className="flex flex-col justify-between gap-xxsm">
				{!isSent && (
					<div
						className={`min-h-5 flex w-full gap-xsm mb-xsm ${
							offerToEdit?.type === "preselection" ? "flex-row" : "flex-col"
						}`}
					>
						<Badge
							label="A envoyer"
							size="medium"
							bgColor="bg-error-light"
							hasBorder
						/>
					</div>
				)}
				<div className="flex gap-xsm">
					<Avatar
						size="xs"
						firstname={thumbnailData?.spsStudent?.firstname || ""}
						lastname={thumbnailData?.spsStudent?.lastname || ""}
						image={thumbnailData?.spsStudent?.avatar?.filePath || ""}
					/>
					<div>
						<div className="flex flex-row gap-xxsm">
							{isSent && (
								<Icon icon={doneAllOutline} className="text-success-dark" />
							)}
							<p className="text-base font-bold text-primary-700P">
								{thumbnailData?.spsStudent?.firstname}{" "}
								{thumbnailData?.spsStudent?.lastname}
							</p>
						</div>
						<p className="text-xxsm font-normal text-primary-700P">
							{thumbnailData?.spsStudent?.promotion?.name || "-"}
						</p>
					</div>
				</div>
				<div className="flex items-center justify-start gap-xxsm text-xxsm font-normal text-primary-300">
					<Icon icon={pinOutline} /> {thumbnailData?.spsStudent?.city || "-"}
				</div>
			</div>
			<div className="flex items-center justify-center">
				<Icon
					icon={chevronRightOutline}
					className={`${
						isSelected ? "block" : "hidden"
					} h-6 w-6 text-primary-700P`}
				/>
			</div>
		</div>
	);
}
