import type { SchoolType } from "@/types/schoolTypes";
import { Avatar } from "@designSystem/atoms/Avatar";
import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { Modal } from "@designSystem/organisms/modal/Modal";
import { setCookie } from "@tools/Cookies";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { fetchProfiles } from "./ChooseProfileAPI";

export type ProfileType = {
	avatar: string;
	name: string;
	token: string;
	interface: "company" | "student" | "referent";
	schoolProfiles?: Partial<SchoolType>[];
};

export function ChooseProfile() {
	const [profiles, setProfiles] = useState<ProfileType[]>([]);
	const [currentEntProfile, setCurrentEntProfile] =
		useState<ProfileType | null>(null);
	const [loading, setLoading] = useState(true);
	const [showSchoolsModal, setShowSchoolsModal] = useState(false);

	const getData = async () => {
		const data = await fetchProfiles();
		if (data.length === 1) {
			setInterface(data[0].token, data[0].interface);
		}
		setProfiles(data);
		setLoading(false);
	};

	useEffect(() => {
		getData();
	}, []);

	const setInterface = (token: string, interfaceName: string, url = "/") => {
		setCookie("token", token);
		setCookie("interface", interfaceName);

		const redirectTo = new URLSearchParams(window.location.search).get(
			"redirectTo",
		);
		if (redirectTo) {
			window.location.replace(`${redirectTo}`);
			return;
		}

		window.location.href = url;
	};

	return (
		<div className="h-full w-full flex flex-col gap-lg items-center justify-center">
			<p className="text-md text-center">Quel compte souhaites-tu utiliser ?</p>
			{loading ? (
				<div className="flex gap-xlg flex-wrap justify-between">
					{["#BFE2DE", "#e3bbab", "#F3D7E8"].map((color) => {
						return (
							<div
								key={color}
								className="flex justify-between flex-col items-center"
							>
								<Skeleton height={144} width={144} circle baseColor={color} />
								<Skeleton height={16} width={150} baseColor={color} />
							</div>
						);
					})}
				</div>
			) : (
				<div className="flex gap-xlg flex-wrap justify-center p-sm overflow-y-scroll">
					{profiles.map((profile) => {
						return (
							<div
								key={profile.name}
								className="flex flex-col gap-xsm items-center cursor-pointer hover:scale-105 transition-all w-full lg:w-1/3"
								onClick={() => {
									if (profile.interface === "company") {
										setCurrentEntProfile(profile);
										setShowSchoolsModal(true);
									} else {
										setInterface(profile.token, profile.interface);
									}
								}}
							>
								<Avatar
									image={profile.avatar}
									firstname={profile.name}
									size="xl"
									className="border-[3px] border-white shadow-xlg"
								/>
								<p className="font-semibold text-center">
									{profile.name} - Espace{" "}
									{profile.interface === "student" && "etudiant"}
									{profile.interface === "referent" && "école"}
									{profile.interface === "company" && "entreprise"}
								</p>
							</div>
						);
					})}
					{profiles.length === 0 && (
						<p className="text-sm">Aucun compte n'a été trouvé</p>
					)}
					<Modal
						title="Choisissez l'espace à rejoindre"
						show={showSchoolsModal}
						onClose={() => {
							setShowSchoolsModal(false);
							setCurrentEntProfile(null);
						}}
						body={
							<div>
								{currentEntProfile?.schoolProfiles?.map((school) => {
									return (
										<div
											key={school.id}
											className="flex justify-between items-center border-t border-primary-100 py-sm"
										>
											<div className="flex items-center gap-sm">
												<Avatar
													firstname={school.name}
													image={school.logo?.filePath || null}
												/>
												<span className="font-bold">{school.name}</span>
											</div>

											<ButtonPrimary
												label="Accéder à cet espace"
												onClick={() => {
													setInterface(
														currentEntProfile?.token as string,
														currentEntProfile?.interface as string,
														`/?${school.subdomain}`,
													);
												}}
											/>
										</div>
									);
								})}
							</div>
						}
					/>
				</div>
			)}
		</div>
	);
}
